import React from "react";
import cn from "classnames";
import styles from "./ProfileInformation.module.sass";
import Item from "../Item";
import TextInput from "../../../components/TextInput";
import { addNotification } from "../../../components/Notification";

const ProfileInformation = ({ className, userData, setUserData, updateUserHandler }) => {

  return (
    <Item
      className={cn(styles.card, className)}
      title="Basic information"
      classTitle="title-green"
    >
      <div className={styles.fieldset}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Customer Id"
            name="customerId"
            type="customerId"
            required
            disabled={true}
            value={userData?.id === undefined || userData?.id === null ? "---" : userData?.id}
          />
          <TextInput
            className={styles.field}
            label="User Id"
            name="userId"
            type="userId"
            required
            disabled={true}
            value={userData?.uid === null || userData?.uid === undefined ? "---" : userData?.uid}
          />
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="First Name"
            name="firstName"
            required
            value={userData?.first_name === undefined || userData === null ? "---" : userData?.first_name}
            onChange={(e) => { setUserData(userData => ({ ...userData, first_name: e.target.value })) }}
          />
          <TextInput
            className={styles.field}
            label="Last Name"
            name="lastName"
            required
            value={userData?.last_name === null || userData?.last_name === undefined ? "---" : userData?.last_name}
            onChange={(e) => { setUserData(userData => ({ ...userData, last_name: e.target.value })) }}
          />
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Email"
            name="email"
            required
            disabled={true}
            value={userData?.email === null || userData?.email === undefined ? "---" : userData?.email}
          />
          <TextInput
            className={styles.field}
            label="Phone"
            name="phone"
            required
            disabled={true}
            value={userData?.phone === null || userData?.phone === undefined ? "---" : `(${userData?.country})${userData?.phone}`}
          />
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Bank Status"
            name="bankStatus"
            required
            disabled={true}
            value={userData?.bank_status === null || userData?.bank_status === undefined ? "---" : userData?.bank_status === parseInt(process.env.REACT_APP_BANK_STATUS_PENDING) ? "Pending" : userData?.bank_status === parseInt(process.env.REACT_APP_BANK_STATUS_REJECT) ? "Rejected" : userData?.bank_status === parseInt(process.env.REACT_APP_BANK_STATUS_ACCEPT) ? "Accepted" : ""}
          />
          <TextInput
            className={styles.field}
            label="Residence Status"
            name="residenceStatus"
            required
            disabled={true}
            value={userData?.residence_status === null || userData?.residence_status === undefined ? "---" : userData?.residence_status === parseInt(process.env.REACT_APP_RESIDENCE_ACTIVE) ? "Active" : "In-Active"}
          />
        </div>
        <div className={styles.random}>
          <div className={styles.customClass}>
            <TextInput
              className={styles.field}
              label="Email Verification Status"
              name="emailVerified"
              type="text"
              required
              value={
                userData?.status === undefined || userData?.status === null ? "" : userData?.status === parseInt(process.env.REACT_APP_UNVERIFIED_EMAIL_CUSTOMERS) ? "Unverified" :
                  userData?.status === undefined || userData?.status === null ? "" : userData?.status === parseInt(process.env.REACT_APP_VERIFIED_EMAIL_CUSTOMERS) ? "Verified" :
                    userData?.status === undefined || userData?.status === null ? "" : userData?.status === parseInt(process.env.REACT_APP_FREEZED_EMAIL_CUSTOMERS) ? "Freezed" :
                      null
              }
              disabled={true}
            />
          </div>
          <div className={styles.row}>
            {
              userData?.status === parseInt(process.env.REACT_APP_VERIFIED_EMAIL_CUSTOMERS) ?
                <div className={styles.buttonClass}>
                  <button
                    className={cn("button")}
                    style={{ background: 'red' }}
                    type="button"
                    onClick={() => {
                      setUserData(userData => ({ ...userData, status: parseInt(process.env.REACT_APP_FREEZED_EMAIL_CUSTOMERS) }))
                    }}
                  >
                    <span>Freeze</span>
                  </button>
                </div>
                :
                userData?.status === parseInt(process.env.REACT_APP_FREEZED_EMAIL_CUSTOMERS) ?
                  <div className={styles.buttonClass}>
                    <button
                      className={cn("button")}
                      style={{ background: 'green' }}
                      type="button"
                      onClick={() =>
                        setUserData(userData => ({ ...userData, status: parseInt(process.env.REACT_APP_VERIFIED_EMAIL_CUSTOMERS) }))
                      }
                    >
                      <span>Activate</span>
                    </button>
                  </div>
                  :
                  ""
            }
          </div>
        </div>
        <div className={styles.random}>
          <div className={styles.customClass}>
            <TextInput
              className={styles.field}
              label="KYC Verification Status"
              name="kycVerification"
              type="text"
              required
              disabled={true}
              value={
                userData?.kyc_verification === undefined || userData?.kyc_verification === null ? "" : userData?.kyc_verification === parseInt(process.env.REACT_APP_KYC_STATUS_PENDING) ? "Pending" :
                  userData?.kyc_verification === undefined || userData?.kyc_verification === null ? "" : userData?.kyc_verification === parseInt(process.env.REACT_APP_KYC_STATUS_INPROCESS) ? "In-process" :
                    userData?.kyc_verification === undefined || userData?.kyc_verification === null ? "" : userData?.kyc_verification === parseInt(process.env.REACT_APP_KYC_STATUS_DECLINED) ? "Declined" :
                      userData?.kyc_verification === undefined || userData?.kyc_verification === null ? "" : userData?.kyc_verification === parseInt(process.env.REACT_APP_KYC_STATUS_COMPLETED) ? "Completed" :
                        userData?.kyc_verification === undefined || userData?.kyc_verification === null ? "" : userData?.kyc_verification === parseInt(process.env.REACT_APP_KYC_STATUS_ABANDONED) ? "Abadoned" :
                          userData?.kyc_verification === undefined || userData?.kyc_verification === null ? "" : userData?.kyc_verification === parseInt(process.env.REACT_APP_KYC_STATUS_ERROR) ? "Error" :
                            userData?.kyc_verification === undefined || userData?.kyc_verification === null ? "" : userData?.kyc_verification === parseInt(process.env.REACT_APP_KYC_STATUS_REVIEW) ? "In review"
                              : null
              }
            />
          </div>
          <div className={styles.row}>
            {
              userData?.kyc_verification === parseInt(process.env.REACT_APP_KYC_STATUS_REVIEW) &&
              <>
                <div className={styles.buttonClass}>
                  <button
                    className={cn("button")}
                    style={{ background: 'green' }}
                    type="button"
                    onClick={() => {
                      setUserData(userData => ({ ...userData, kyc_verification: parseInt(process.env.REACT_APP_KYC_STATUS_COMPLETED) }))
                    }}
                  >
                    <span>Accept</span>
                  </button>
                </div>
                <div className={styles.buttonClass}>
                  <button
                    className={cn("button")}
                    style={{ background: 'red' }}
                    type="button"
                    onClick={() => {
                      setUserData(userData => ({ ...userData, kyc_verification: parseInt(process.env.REACT_APP_KYC_STATUS_PENDING) }))
                    }}
                  >
                    <span>Reject</span>
                  </button>
                </div>
              </>
            }
          </div>
        </div>
      </div>
      <button
        onClick={(e) => {
          e.preventDefault();
          if (userData?.name !== "") {
            updateUserHandler(userData);
          }
          else {
            addNotification({
              title: 'Alert',
              message: "Name field can't be empty.",
              type: 'danger'
            });
          }
        }}
        className={cn("button-small", styles.button)}
      >
        Save Changes
      </button>
    </Item>
  );
};

export default ProfileInformation;

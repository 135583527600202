import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Promote.module.sass";
import Overview from "./Overview";
import requestHandler from "../../actions/httpClient";
import LoaderScreen from "../../components/LoaderScreen";
import { addNotification } from "../../components/Notification";
import { format } from "date-fns";

const intervalvalues = [
  {
    cId: "hour",
    cValue: "Hour",
  },
  {
    cId: 1,
    cValue: "Day",
  },
  {
    cId: 7,
    cValue: "Week",
  },
  {
    cId: 30,
    cValue: "Month",
  },
  {
    cId: 365,
    cValue: "Year",
  },
  {
    cId: "custom",
    cValue: "Custom Range",
  },
];

const addressTypeValues = [
  {
    cId: "credited",
    cValue: "Credited",
  },
  {
    cId: "debited",
    cValue: "Debited",
  },
  {
    cId: "deposit",
    cValue: "Deposit",
  },
  {
    cId: "withdraw",
    cValue: "Withdraw",
  },
  {
    cId: "cashrecharge",
    cValue: "Cash Recharge",
  },
  // {
  //   cId: "topup",
  //   cValue: "Top-Up",
  // },
];

const Finance = () => {
  const [intervalValue, setIntervalvalue] = useState(intervalvalues[4].cId);
  const [intervalText, setIntervalText] = useState(intervalvalues[4].cValue);
  const [addressValue, setAddressvalue] = useState(addressTypeValues[0].cId);
  const [addressText, setAddressText] = useState(addressTypeValues[0].cValue);
  const [financeData, SetFinanceData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    financeDataHandler();
  }, [intervalValue, addressValue, startDate, endDate]);

  const financeDataHandler = async () => {
    setLoading(true);
    let formData = {
      interval: intervalValue,
      type: addressValue,
      startdate: startDate ? format(startDate, "yyyy-MM-dd HH:mm:ss") : null,
      enddate: endDate ? format(endDate, "yyyy-MM-dd HH:mm:ss") : null,
    };
    try {
      const getAllStatsPayload = await requestHandler("transactionMonitorValue/finance-transaction-list", "post", formData, "jwt_token");
      setLoading(false);
      SetFinanceData(getAllStatsPayload?.data);
    }
    catch (e) {
      addNotification({
        title: "Error",
        message: e?.data?.error,
        type: "danger",
      });
      setLoading(false);
    }
  };

  return (
    <>
      <div className={styles.section}>
        <Overview
          className={styles.card}
          intervalText={intervalText}
          setIntervalText={setIntervalText}
          intervalValue={intervalValue}
          setIntervalvalue={setIntervalvalue}
          intervalvalues={intervalvalues}
          addressValue={addressValue}
          setAddressvalue={setAddressvalue}
          addressText={addressText}
          setAddressText={setAddressText}
          addressTypeValues={addressTypeValues}
          financeData={financeData}
          financeDataHandler={financeDataHandler}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
      </div>

      {loading && <LoaderScreen />}
    </>
  );
};

export default Finance;

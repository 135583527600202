import React, { useState } from "react";
import cn from "classnames";
import styles from "./Promote.module.sass";
import RecentPost from "./RecentPost"
import requestHandler from "../../../actions/httpClient";
import { addNotification } from "../../../components/Notification";
import LoaderScreen from "../../../components/LoaderScreen";

const Promote = ({ userData, getUserDetailsHandler }) => {
  const [loading, setLoading] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [directorDetails, setDirectorDetails] = useState([]);
  const [onfidoWindowModal, setOnfidoWindowModal] = useState(false);
  const [token, setToken] = useState("");
  const [workFlowRunId, setWorkFlowRunId] = useState("");
  const [applicantId, setApplicantId] = useState("");

  const addDirectorHandler = async (firstName, lastName, email, contactNumber, countryCode, setActiveIndex) => {
    setLoading(true);
    let data = {
      merchant_id: userData?.id,
      director_first_name: firstName,
      director_last_name: lastName,
      email: email,
      phone: contactNumber,
      country_code: countryCode,
    };
    try {
      const addDirectorPayload = await requestHandler("merchant/create-director", "post", data, "jwt_token");
      setLoading(false);
      if (addDirectorPayload.status === 200) {
        addNotification({
          title: "Success",
          message: addDirectorPayload?.data?.message,
          type: "success"
        });
        // setVisibleModal(false);
        setActiveIndex(1);
        getUserDetailsHandler();
        checkDirectorKycHandler(addDirectorPayload?.data?.data?.id);
      };
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: e?.data?.message,
        type: "danger",
      });
    };
  };

  const checkDirectorKycHandler = async (director_id) => {
    setLoading(true);
    let data = {
      director_id: director_id
    };
    try {
      const checkDirectorKycPayload = await requestHandler("merchant/business-onfido-check", "post", data, "jwt_token");
      setLoading(false);
      updateOnfidoHandler(checkDirectorKycPayload?.data?.data?.businesskyc?.applicant_kyc_id);
    }
    catch (e) {
      setLoading(false);
    };
  };

  const updateOnfidoHandler = async (applicant_id) => {
    setLoading(true);
    let data = {
      applicant_id: applicant_id,
      type: "web",
    };
    try {
      const updateOnfidoPayload = await requestHandler("merchant/update-onfido-token", "post", data, "jwt_token");
      setLoading(false);
      if (updateOnfidoPayload?.status === 200) {
        setToken(updateOnfidoPayload?.data?.data?.sdk_token);
        setWorkFlowRunId(updateOnfidoPayload?.data?.data?.workflow_run_id);
        setApplicantId(updateOnfidoPayload?.data?.data?.applicant_kyc_id);
      }
    }
    catch (e) {
      setLoading(false);
    };
  };

  const getDirectorDetails = async (id) => {
    setLoading(true);
    let data = {
      director_id: id,
    };
    try {
      const directorDetailsPayload = await requestHandler("merchant/director-detail", "post", data, "jwt_token");
      setLoading(false);
      setDirectorDetails(directorDetailsPayload?.data?.data);
    }
    catch (e) {
      setLoading(false);
    };
  };

  return (
    <>
      {loading && <LoaderScreen />}
      <div className={styles.section}>
        <RecentPost
          userData={userData}
          addDirectorHandler={addDirectorHandler}
          visibleModal={visibleModal}
          setVisibleModal={setVisibleModal}
          setDirectorDetails={setDirectorDetails}
          directorDetails={directorDetails}
          onfidoWindowModal={onfidoWindowModal}
          setOnfidoWindowModal={setOnfidoWindowModal}
          token={token}
          workFlowRunId={workFlowRunId}
          applicantId={applicantId}
          getUserDetailsHandler={getUserDetailsHandler}
          getDirectorDetails={getDirectorDetails}
          updateOnfidoHandler={updateOnfidoHandler}
        />
      </div>
    </>
  );
};

export default Promote;

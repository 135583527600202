import React from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Modal from "../../../components/Modal";
import Row from "./Row";
import NewPost from "./NewPost";
import CustomerListSkeleton from "../../../components/Skeleton/CutomerListSkeleton";
import Form from "../../../components/Form";

const RecentPost = ({
  className,
  loading,
  transactionData,
  handleSubmit,
  setSearchField,
  searchField,
  setVisibleModal,
  visibleModal,
  setTransactionDetails,
  transactionDetails,
  status,
  updateTransactionHandler
}) => {

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Recent transactions"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <Form
              className={styles.form}
              value={searchField}
              setValue={setSearchField}
              onSubmit={handleSubmit}
              placeholder="Search by transaction id"
              type="text"
              name="search"
              icon="search"
            />
          </>
        }
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>Deposited Amount</div>
            <div className={styles.col}>Transaction Id</div>
            <div className={styles.col}>Reference Number</div>
            <div className={styles.col}>Status</div>
            <div className={styles.col}>Date</div>
            {parseInt(status) === parseInt(process.env.REACT_APP_DEPOSIT_STATUS_PENDING) && <div className={styles.col}>Action</div>}
          </div>
          {loading && parseInt(status) === parseInt(process.env.REACT_APP_DEPOSIT_STATUS_PENDING) ? <CustomerListSkeleton colCount={6} rowCount={12} /> :
            loading && parseInt(status) === parseInt(process.env.REACT_APP_DEPOSIT_STATUS_SUCCESS) ? <CustomerListSkeleton colCount={5} rowCount={12} />
              : transactionData
                ?.filter((y) => {
                  return y?.txId?.includes(searchField)
                })
                ?.map((x, index) => (
                  <Row
                    item={x}
                    key={index}
                    setVisibleModal={setVisibleModal}
                    setTransactionDetails={setTransactionDetails}
                    status={status}
                    updateTransactionHandler={updateTransactionHandler}
                  />
                ))}
        </div>
      </Card>
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <NewPost
          transactionDetails={transactionDetails}
        />
      </Modal>
    </>
  );
};

export default RecentPost;

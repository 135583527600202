import React, { useState } from "react";
import styles from "./Table.module.sass";
import cn from "classnames";
import TextInput from "../../../components/TextInput";
import Item from "../Item";
import { addNotification } from "../../../components/Notification";

const Table = ({ className, userData, updateResidenceDetails }) => {

  const [address1, setAddress1] = useState(userData?.address === null || userData?.address === undefined ? "" : userData?.address?.address1);
  const [address2, setAddress2] = useState(userData?.address === null || userData?.address === undefined ? "" : userData?.address?.address2);
  const [city, setCity] = useState(userData?.address === null || userData?.address === undefined ? "" : userData?.address?.city);
  const [country, setCountry] = useState(userData?.address === null || userData?.address === undefined ? "" : userData?.address?.country);
  const [state, setState] = useState(userData?.address === null || userData?.address === undefined ? "" : userData?.address?.state);
  const [zipCode, setZipCode] = useState(userData?.address === null || userData?.address === undefined ? "" : userData?.address?.pincode);
  const [image, setImage] = useState(userData?.address === null || userData?.address === undefined ? null : userData?.address?.image);

  return (
    <Item
      className={cn(styles.card, className)}
      title="Residence information"
      classTitle="title-green"
    >
      <div className={styles.profile}>
        <div className={styles.file}>
        </div>
      </div>
      <div>
        <div className={styles.fieldset}>
          <div className={styles.customField}>
            <TextInput
              className={styles.field}
              label="Address 1"
              name="address1"
              value={address1}
              type="text"
              required
              onChange={(e) => { setAddress1(e.target.value) }}
            />
          </div>
          <div className={styles.customField}>
            <TextInput
              className={styles.field}
              label="Address 2"
              name="address2"
              value={address2}
              type="text"
              required
              onChange={(e) => { setAddress2(e.target.value) }}
            />
          </div>
          <div className={styles.customField}>
            <TextInput
              className={styles.field}
              label="City"
              name="city"
              value={city}
              type="text"
              required
              onChange={(e) => { setCity(e.target.value) }}
            />
          </div>
          <div className={styles.customField}>
            <TextInput
              className={styles.field}
              label="Country"
              name="country"
              value={country}
              type="text"
              required
              onChange={(e) => { setCountry(e.target.value) }}
            />
          </div>
          <div className={styles.customField}>
            <TextInput
              className={styles.field}
              label="State"
              name="state"
              value={state}
              type="text"
              required
              onChange={(e) => { setState(e.target.value) }}
            />
          </div>
          <div className={styles.customField}>
            <TextInput
              className={styles.field}
              label="Zip code"
              name="zipcode"
              value={zipCode}
              type="text"
              required
              onChange={(e) => { setZipCode(e.target.value) }}
            />
          </div>
          {userData?.address === null ?
            <div className={styles.customField}>
              <TextInput
                className={styles.field}
                label="Address Proof"
                name="addressProof"
                type="file"
                required
                onChange={(e) => { setImage(e.target.files[0]) }}
              />
            </div>
            :
            <div className={styles.image} onClick={() => { window.open(image, "__blank") }}>
              <img
                className={styles.customImagesClass}
                src={image}
                alt="Proof"
              />
            </div>}
          <br />
          <button
            className={cn("button-small", styles.button)}
            onClick={(e) => {
              e.preventDefault();
              if (address1 !== "" && address2 !== "" && city !== "" && state !== "" && country !== "" && zipCode !== "" && image !== null) {
                updateResidenceDetails(userData?.id, address1, address2, city, country, state, zipCode, image);
              }
              else {
                addNotification({
                  title: "Alert",
                  message: "Please fill all the fields and make sure entered data is valid.",
                  type: "danger",
                })
              }
            }}
          >
            Save Changes
          </button>
        </div>
      </div>
    </Item>
  );
};

export default Table;

import React, { useRef, useState } from "react";
import cn from "classnames";
import styles from "./Form.module.sass";
import SimpleReactValidator from "simple-react-validator";
import LoaderScreen from "../../../../components/LoaderScreen";
import TextInput from "../../../../components/TextInput";
import CustomDropdown from "../../../../components/CustomDropdown";
import Dropdown from "../../../../components/Dropdown";

const intervals = [
  { "cId": parseInt(process.env.REACT_APP_REFERRAL_STATUS_ENABLE), "cValue": "Enable" },
  { "cId": parseInt(process.env.REACT_APP_REFERRAL_STATUS_DISABLE), "cValue": "Disable" }
];

const bonusesValue = ["Referral", "Signup"];

const Form = ({ loading, addReferralHandler }) => {
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, setForceUpdate] = useState();
  const [referralName, setReferralName] = useState("");
  const [referralAmount, setReferralAmount] = useState("");
  const [referralStatusText, setReferralStatusText] = useState(intervals[0].cValue);
  const [referralStatusValue, setReferralStatusValue] = useState(intervals[0].cId);
  const [bonusValue, setBonusValue] = useState(bonusesValue[0]);

  return (
    <div className={styles.share}>
      {loading && <LoaderScreen />}
      <div className={styles.head}>
        <div className={cn("title-red", styles.title)}>Add New Bonus</div>
      </div>
      <TextInput
        className={styles.field}
        label="Name"
        name="bonusName"
        type="text"
        placeholder="Please enter a value"
        onChange={(e) => { setReferralName(e.target.value) }}
        required
      />
      <span className={styles.errorMessage}>
        {simpleValidator.current.message("bonusName", referralName, "required")}
      </span>
      <TextInput
        className={styles.field}
        label="Amount"
        name="bonusAmount"
        type="text"
        value={referralAmount}
        placeholder="Please enter a value"
        onChange={(e) => {
          const re = /^-?\d*\.?\d*$/;
          if (e.target.value === '' || re.test(e.target.value)) {
            setReferralAmount(e.target.value)
          }
        }}
        required
      />
      <span className={styles.errorMessage}>
        {simpleValidator.current.message("bonusAmount", referralAmount, "required")}
      </span>
      {/* <Dropdown
        label="Bonus Type"
        className={styles.dropdown}
        classDropdownHead={styles.dropdownHead}
        value={bonusValue}
        setValue={setBonusValue}
        options={bonusesValue}
        small
      /> */}
      <CustomDropdown
        label="Status"
        className={styles.dropdown}
        classDropdownHead={styles.dropdownHead}
        value={referralStatusValue}
        setValue={setReferralStatusValue}
        text={referralStatusText}
        setText={setReferralStatusText}
        options={intervals}
      />
      <button className={cn("button button-small", styles.button)}
        onClick={(e) => {
          e.stopPropagation();
          if (simpleValidator.current.allValid()) {
            addReferralHandler(referralName, referralAmount, referralStatusValue, bonusValue);
          }
          else {
            simpleValidator.current.showMessages();
            setForceUpdate(1);
          }
        }}>
        <span>Continue</span>
      </button>
    </div>
  );
};

export default Form;

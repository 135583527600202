import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Promote.module.sass";
import RecentPost from "./RecentPost";
import requestHandler from "../../actions/httpClient";
import { addNotification } from "../../components/Notification";

const Promote = ({ status }) => {
  const [loading, setLoading] = useState(false);
  const [merchantData, setMerchantData] = useState([]);
  const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
    getMerchantListHandler();
  }, [pageCount]);

  const getMerchantListHandler = async () => {
    setLoading(true);
    let data = {
      role: parseInt(process.env.REACT_APP_MERCHANT_ROLE),
      // role: 2,
      kyc_verification: parseInt(status),
    };
    try {
      const getMerchantPayload = await requestHandler(
        `user/index?page=${pageCount}`,
        "post",
        data,
        "jwt_token"
      );
      setLoading(false);
      setMerchantData(getMerchantPayload?.data?.data);
    } catch (e) {
      setLoading(false);
    }
  };

  const updateKycHandler = async (id, status) => {
    setLoading(true);
    let data = {
      merchant_id: id,
      status: status,
      role: parseInt(process.env.REACT_APP_MERCHANT_ROLE),
    };
    try {
      const updateKycPayload = await requestHandler(
        "update-status-kyc",
        "post",
        data,
        "jwt_token"
      );
      setLoading(false);
      if (updateKycPayload.status === 200) {
        addNotification({
          title: "Success",
          message: updateKycPayload?.data?.message,
          type: "success",
        });
        getMerchantListHandler();
      }
    } catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: "Something went wrong. Please try again later.",
        type: "success",
      });
    }
  };

  return (
    <>
      <div className={styles.section}>
        <RecentPost
          merchantData={merchantData}
          loading={loading}
          pageCount={pageCount}
          setPageCount={setPageCount}
          status={status}
          updateKycHandler={updateKycHandler}
        />
      </div>
    </>
  );
};

export default Promote;

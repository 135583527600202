import React from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import { toLocaleStringDateFormat, getDigitsAfterDecimal } from "../../../../../components/helper"

const Row = ({ item, setVisibleModal, setTransactionDetails }) => {

  return (
    <div className={styles.row} onClick={() => {
      setVisibleModal(true);
      setTransactionDetails(item);
    }}>
      <div className={styles.col}>
        SRD {getDigitsAfterDecimal(parseFloat(item?.amount), 2)}
      </div>
      <div className={styles.col}>
        {item?.txId}
      </div>
      <div className={styles.col}>
        {item?.payment_reference === null || item?.payment_reference === undefined ? "---" : item?.payment_reference}
      </div>
      <div className={styles.col}>
        {item?.status === parseInt(process.env.REACT_APP_DEPOSIT_STATUS_PENDING) ?
          <div className={cn("status-red-dark", styles.distribution)}>
            Pending
          </div>
          :
          item?.status === parseInt(process.env.REACT_APP_DEPOSIT_STATUS_SUCCESS) ?
            <div className={cn("status-green-dark", styles.distribution)}>
              Success
            </div>
            :
            <div className={cn("status-red-dark", styles.distribution)}>
              Declined
            </div>
        }
      </div>
      <div className={styles.col}>
        {toLocaleStringDateFormat(item?.created_at)}
      </div>
    </div>
  );
};

export default Row;

import React, { useRef, useState } from "react";
import cn from "classnames";
import styles from "./Form.module.sass";
import SimpleReactValidator from "simple-react-validator";
import LoaderScreen from "../../../../components/LoaderScreen";
import TextInput from "../../../../components/TextInput";
import Dropdown from "../../../../components/Dropdown";

const intervals = ["Enable", "Disable"];

const Form = ({ loading, addCategoryHandler }) => {
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, setForceUpdate] = useState();
  const [categoryName, setCategoryName] = useState("");
  const [categoryImage, setCategoryImage] = useState();
  const [cashbackPercentage, setCashbackPercentage] = useState("");
  const [sorting, setSorting] = useState(intervals[0]);
  const [transactionFee, setTransactionFee] = useState("");
  const [creditTransactionFee, setCreditTransactionFee] = useState("");

  return (
    <div className={styles.share}>
      {loading && <LoaderScreen />}
      <div className={styles.head}>
        <div className={cn("title-red", styles.title)}>Add New Category</div>
      </div>
      <TextInput
        className={styles.field}
        label="Category Name"
        name="categoryName"
        type="text"
        placeholder="Please enter a value"
        onChange={(e) => { setCategoryName(e.target.value) }}
        required
        value={categoryName}
      />
      <span className={styles.errorMessage}>
        {simpleValidator.current.message("categoryName", categoryName, "required")}
      </span>
      <TextInput
        className={styles.field}
        label="Category Image"
        name="categoryImage"
        type="file"
        onChange={(e) => { setCategoryImage(e.target.files[0]) }}
        required
      />
      <span className={styles.errorMessage}>
        {simpleValidator.current.message("categoryImage", categoryImage, "required")}
      </span>
      <TextInput
        className={styles.field}
        label="Cashback Percentage"
        name="cashbackPercentage"
        type="text"
        placeholder="Please enter a value"
        onChange={(e) => { setCashbackPercentage(e.target.value) }}
        required
        value={cashbackPercentage}
      />
      <span className={styles.errorMessage}>
        {simpleValidator.current.message("cashbackPercentage", cashbackPercentage, "required")}
      </span>
      <TextInput
        className={styles.field}
        label="Debit Transaction Fee"
        name="debitTransactionFee"
        type="text"
        placeholder="Please enter a value"
        onChange={(e) => { setTransactionFee(e.target.value) }}
        required
        value={transactionFee}
      />
      <span className={styles.errorMessage}>
        {simpleValidator.current.message("debitTransactionFee", transactionFee, "required")}
      </span>
      <TextInput
        className={styles.field}
        label="Credit Transaction Fee"
        name="creditTransactionFee"
        type="text"
        placeholder="Please enter a value"
        onChange={(e) => { setCreditTransactionFee(e.target.value) }}
        required
        value={creditTransactionFee}
      />
      <span className={styles.errorMessage}>
        {simpleValidator.current.message("creditTransactionFee", creditTransactionFee, "required")}
      </span>
      <Dropdown
        label="Status"
        className={styles.dropdown}
        classDropdownHead={styles.dropdownHead}
        value={sorting}
        setValue={setSorting}
        options={intervals}
        small
      />
      <button className={cn("button button-small", styles.button)}
        onClick={(e) => {
          e.stopPropagation();
          if (simpleValidator.current.allValid()) {
            addCategoryHandler(categoryName, categoryImage, sorting, cashbackPercentage, transactionFee, creditTransactionFee);
          }
          else {
            simpleValidator.current.showMessages();
            setForceUpdate(1);
          }
        }}>
        <span>Continue</span>
      </button>
    </div>
  );
};

export default Form;

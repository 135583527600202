import React from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import { toLocaleStringDateFormat, getDigitsAfterDecimal } from "../../../../../components/helper";

const Row = ({ item }) => {

  return (
    <div className={styles.row}>
      <div className={styles.col}>
        {item?.id}
      </div>
      <div className={styles.col}>
        {item?.first_name === undefined || item?.first_name === null || item?.last_name === null || item?.last_name === undefined ? "---" : `${item?.first_name} ${item?.last_name}`}
      </div>
      <div className={styles.col}>
        SRD {getDigitsAfterDecimal(item?.wallet?.balance, 2)}
      </div>
      <div className={styles.col}>
        {item?.uid}
      </div>
      <div className={styles.col}>
        {item?.status === parseInt(process.env.REACT_APP_VERIFIED_EMAIL_CUSTOMERS) ?
          <div className={cn("status-green-dark", styles.distribution)}>
            Active
          </div>
          :
          item?.status === parseInt(process.env.REACT_APP_FREEZED_EMAIL_CUSTOMERS) ?
            <div className={cn("status-red-dark", styles.distribution)}>
              In-Active
            </div>
            :
            ""
        }
      </div>
      <div className={styles.col}>
        {toLocaleStringDateFormat(item?.created_at)}
      </div>
    </div>
  );
};

export default Row;

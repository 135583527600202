import React, { useState } from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Row from "./Row";
import CustomerListSkeleton from "../../../components/Skeleton/CutomerListSkeleton";
import Form from "../../../components/Form";
import Modal from "../../../components/Modal";
import NewPost from "./NewPost";

const RecentPost = ({
  className,
  loading,
  subUsersData,
  setSearchField,
  searchField,
  setVisibleModal,
  visibleModal,
  searchCustomerHandler,
}) => {
  const [transactionDetails, setTransactionDetails] = useState([]);
  const handleSubmit = (e) => {
    e.preventDefault();
    searchCustomerHandler();
  };
  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Recent Users"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <Form
              className={styles.form}
              value={searchField}
              setValue={setSearchField}
              placeholder="Search by  UId"
              type="text"
              name="search"
              icon="search"
              onSubmit={handleSubmit}
            />
            <div className={styles.dropdown}>
              <button
                className={cn("button-small")}
                onClick={(e) => {
                  e.preventDefault();
                  searchCustomerHandler();
                }}
              >
                Submit
              </button>
            </div>
          </>
        }
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>Parent Id</div>
            <div className={styles.col}>C Id</div>
            <div className={styles.col}>Customer</div>
            <div className={styles.col}>U Id</div>
            <div className={styles.col}>Registration Date</div>
          </div>
          {loading ? (
            <CustomerListSkeleton colCount={6} rowCount={12} />
          ) : subUsersData?.length !== 0 ? (
            subUsersData?.map((x, index) => (
              <Row
                item={x}
                key={index}
                visibleModal={visibleModal}
                setVisibleModal={setVisibleModal}
                setTransactionDetails={setTransactionDetails}
              />
            ))
          ) : (
            "No data found"
          )}
        </div>
      </Card>
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <NewPost transactionDetails={transactionDetails} />
      </Modal>
    </>
  );
};

export default RecentPost;

import styles from "./Details.module.sass";
import cn from "classnames";

const DirectorDetails = ({ directorDetails }) => {

  return (
    <>
      <div className={styles.share}>
        <div className={styles.head}>
          <div className={cn("title-red", styles.title)}>Details</div>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.text}>
            Applicant Kyc Id
          </div>
          <div className={styles.col}>
            {directorDetails?.applicant_kyc_id}
          </div>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.text}>
            Workflowrun Kyc Id
          </div>
          <div className={styles.col}>
            {directorDetails?.workflow_run_id ? directorDetails?.workflow_run_id : "---"}
          </div>
        </div>
      </div>
    </>
  );
};

export default DirectorDetails;

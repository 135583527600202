import React, { useRef, useState } from "react";
import cn from "classnames";
import styles from "./Entry.module.sass";
import TextInput from "../../../components/TextInput";
import Image from "../../../components/Image";
import SimpleReactValidator from 'simple-react-validator';
import Loader from "../../../components/Loader";

const Entry = ({ loginHandler }) => {
  const simpleValidator = useRef(new SimpleReactValidator())
  const [email, setEmail] = useState("")
  const [, forceUpdate] = useState();
  const [passwordType, setPasswordType] = useState("password");
  const [password, setPassword] = useState("")

  const togglePassword = (e) => {
    e.preventDefault();
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    };
    setPasswordType("password")
  };

  return (
    <div className={styles.entry}>
      {/* <div className={styles.head}>
        <div className={styles.info}>Sign up with Open account</div>
        <div className={styles.btns}>
          <button className={cn("button-stroke", styles.button)}>
            <img src="/images/content/google.svg" alt="Google" />
            Google
          </button>
          <button className={cn("button-stroke", styles.button)}>
            <Image
              className={styles.pic}
              src="/images/content/apple-dark.svg"
              srcDark="/images/content/apple-light.svg"
              alt="Apple"
            />
            Apple ID
          </button>
        </div>
      </div> */}
      <form className={styles.form} onSubmit={(e) => { e.preventDefault() }}>
        <div className={styles.body}>
          {/* <div className={styles.info}>Or continue with email address</div> */}
          <TextInput
            className={styles.field}
            name="email"
            type="email"
            placeholder="Email"
            required
            icon="mail"
            onChange={(e) => { setEmail(e.target.value) }}
            onBlur={() => simpleValidator.current.showMessageFor("email")}
          />
          <span className={styles.errorMessage}>
            {simpleValidator.current.message("email", email, "required|email")}
          </span>
          <TextInput
            className={styles.field}
            name="password"
            type={passwordType}
            placeholder="Password"
            required
            icon="lock"
            view
            togglePassword={togglePassword}
            onChange={(e) => { setPassword(e.target.value) }}
            onBlur={() => simpleValidator.current.showMessageFor("password")}
          />
          <span className={styles.errorMessage}>
            {simpleValidator.current.message("password", password, "required|min:9")}
          </span>
          {/* <div className={styles.customImg}>
            {captchaLoading ? <Loader className={styles.loader} /> : <img className={styles.captcha} src={`data:image/svg+xml;base64,${base64data}`} alt="Captcha" />}
          </div> */}
          {/* <TextInput
            className={styles.field}
            name="captcha"
            placeholder="Enter Captcha"
            required
            icon="expand"
            value={captchaField}
            onChange={(e) => { setCaptchaField(e.target.value) }}
            onBlur={() => simpleValidator.current.showMessageFor("captcha")}
          /> */}
          {/* {simpleValidator.current.message("captcha", captchaField, "required")} */}
          <button className={cn("button", styles.button)} onClick={(e) => {
            e.preventDefault();
            if (simpleValidator.current.allValid()) {
              loginHandler(email, password);
            } else {
              simpleValidator.current.showMessages();
              forceUpdate(1);
            }
          }}>
            Continue
          </button>
          {/* <div className={styles.note}>
            This site is protected by reCAPTCHA and the Google Privacy Policy.
            This site is protected by CAPTCHA.
          </div> */}
        </div>
      </form>
    </div>
  );
};

export default Entry;

import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Promote.module.sass";
import RecentPost from "./RecentPost";
import requestHandler from "../../actions/httpClient";

const intervals = ["Cash Recharge", "Others"];
const Promote = () => {
  const [loading, setLoading] = useState(false);
  const [transactionData, setTransactionData] = useState([]);
  const [searchField, setSearchField] = useState("");
  const [visibleModal, setVisibleModal] = useState(false);
  const [sorting, setSorting] = useState(intervals[0]);

  useEffect(() => {
    getTransactionsData();
  }, [sorting]);

  const getTransactionsData = async () => {
    setLoading(true);
    let data = {
      type: sorting === "Cash Recharge" ? "cashrecharge" : "credited",
    };
    try {
      const getTransactionDataPayload = await requestHandler(
        "transaction/list",
        "post",
        data,
        "jwt_token"
      );
      setLoading(false);
      setTransactionData(getTransactionDataPayload?.data?.data);
    } catch (e) {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <div className={styles.section}>
        <RecentPost
          loading={loading}
          transactionData={transactionData}
          handleSubmit={handleSubmit}
          searchField={searchField}
          setSearchField={setSearchField}
          visibleModal={visibleModal}
          setVisibleModal={setVisibleModal}
          sorting={sorting}
          setSorting={setSorting}
          intervals={intervals}
        />
      </div>
    </>
  );
};

export default Promote;

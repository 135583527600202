import React, { useState } from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";
import Row from "./Row";
import LoaderScreen from "../../../components/LoaderScreen";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const intervals = ["Category 1", "Category 2", "Category 3"];

const RecentPost = ({
  className,
  merchantData,
  loading,
  setPageCount,
  pageCount,
  status,
  updateKycHandler,
}) => {
  const [sorting, setSorting] = useState(intervals[0]);

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Recently Added"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            {/* <Dropdown
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              value={sorting}
              setValue={setSorting}
              options={intervals}
              small
            /> */}
            {/* <Link
              className={cn("button-small", styles.button)}
              to="/add-merchant"
            >
              Add New Merchant
            </Link> */}
          </>
        }
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>C Id</div>
            <div className={styles.col}>Customer</div>
            <div className={styles.col}>U Id</div>
            <div className={styles.col}>KYC Status</div>
            <div className={styles.col}>Bank Status</div>
            <div className={styles.col}>Residence Status</div>
            <div className={styles.col}>Registration Date</div>
            {parseInt(status) ===
              parseInt(process.env.REACT_APP_KYC_STATUS_INPROCESS) && (
              <div className={styles.col}>Action</div>
            )}
          </div>
          {loading ? (
            <LoaderScreen />
          ) : (
            merchantData?.data?.map((x, index) => (
              <Row
                item={x}
                key={index}
                status={status}
                updateKycHandler={updateKycHandler}
              />
            ))
          )}
        </div>
        {merchantData?.data?.length > 0 && (
          <>
            <div className={styles.foot}>
              {merchantData?.prev_page_url !== null && (
                <button
                  className={cn("button-stroke button-small", styles.button)}
                  onClick={() => {
                    setPageCount(pageCount - 1);
                  }}
                >
                  <span>Previous</span>
                </button>
              )}
              {merchantData?.next_page_url !== null && (
                <button
                  className={cn("button-stroke button-small", styles.button)}
                  onClick={() => {
                    setPageCount(pageCount + 1);
                  }}
                >
                  <span>Next</span>
                </button>
              )}
            </div>
          </>
        )}
      </Card>
      {/* <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <Form />
      </Modal> */}
    </>
  );
};

export default RecentPost;

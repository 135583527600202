import React, { useRef, useState } from "react";
import styles from "./NewPost.module.sass";
import cn from "classnames";
import TextInput from "../../../../components/TextInput";
import SimpleReactValidator from "simple-react-validator";
import Dropdown from "../../../../components/Dropdown";

const moduleValues = [
  "Deposit", "Cash Recharge", "Transaction", "Topup"
];
const intervalValues = ["Single", "Hour"];

const durationValues = ["24 Hours", "48 Hours", "72 Hours", "96 Hours", "120 Hours"];

const NewPost = ({
  addTMValuesHandler,
}) => {

  const simpleValidator = useRef(new SimpleReactValidator())
  const [, forceUpdate] = useState();
  const [low, setLow] = useState("");
  const [medium, setMedium] = useState("");
  const [high, setHigh] = useState("");
  const [module, setModule] = useState(moduleValues[0]);
  const [interval, setInterval] = useState(intervalValues[0]);
  const [duration, setDuration] = useState(durationValues[0]);

  return (
    <>
      <div className={styles.details}>
        <div className={cn("title-purple", styles.title)}>Add Transaction</div>
        <div className={styles.regionDropdown}>
          <Dropdown
            label="Module"
            className={styles.dropdown}
            classDropdownHead={styles.dropdownHead}
            value={module}
            setValue={setModule}
            options={moduleValues}
            small
          />
          <div className={styles.customField}>
            <TextInput
              className={styles.field}
              label="Low"
              name="low"
              type="text"
              value={low}
              onChange={(e) => {
                const regex = /^\d*\.?\d*$/;
                if (regex.test(e.target.value) || e.target.value === '') {
                  setLow(e.target.value)
                }
              }
              }
              placeholder="Please enter a value"
              required
              onBlur={() => simpleValidator.current.showMessageFor("low")}
            />
            <span className={styles.errorMessage}>
              {simpleValidator.current.message("low", low, "required")}
            </span>
          </div>
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Medium"
            name="medium"
            type="text"
            value={medium}
            onChange={(e) => {
              const regex = /^\d*\.?\d*$/;
              if (regex.test(e.target.value) || e.target.value === '') {
                setMedium(e.target.value)
              }
            }
            } placeholder="Please enter a value"
            required
            onBlur={() => simpleValidator.current.showMessageFor("medium")}
          />
          <span className={styles.errorMessage}>
            {simpleValidator.current.message("medium", medium, "required")}
          </span>
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="High"
            name="high"
            type="text"
            value={high}
            onChange={(e) => {
              const regex = /^\d*\.?\d*$/;
              if (regex.test(e.target.value) || e.target.value === '') {
                setHigh(e.target.value)
              }
            }
            } placeholder="Please enter a value"
            required
            onBlur={() => simpleValidator.current.showMessageFor("high")}
          />
          <span className={styles.errorMessage}>
            {simpleValidator.current.message("high", high, "required")}
          </span>
        </div>
        <div className={styles.regionDropdown}>
          <Dropdown
            label={"Interval"}
            className={styles.dropdown}
            classDropdownHead={styles.dropdownHead}
            value={interval}
            setValue={setInterval}
            options={intervalValues}
            small
          />
        </div>
        {interval?.toLowerCase() === "hour" &&
          <div className={styles.regionDropdown}>
            <Dropdown
              label={"Duration"}
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              value={duration}
              setValue={setDuration}
              options={durationValues}
              small
            />
          </div>
        }
        <div className={styles.control}>
          <button className={cn("button", styles.button)} onClick={(e) => {
            e.preventDefault();
            if (simpleValidator.current.allValid()) {
              addTMValuesHandler(module, low, medium, high, interval, interval?.toLowerCase() === "single" ? 0 : duration.split(" ")[0]);
            } else {
              simpleValidator.current.showMessages();
              forceUpdate(1);
            }
          }
          }>
            <span>Continue</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default NewPost;

import React from "react";
import styles from "./NewPost.module.sass";
import cn from "classnames";
import { toLocaleStringDateFormat } from "../../../../../components/helper";

const NewPost = ({ transactionDetails }) => {

  return (
    <div className={styles.share}>
      <div className={styles.head}>
        <div className={cn("title-red", styles.title)}>Transaction Details</div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>From</div>
        <div className={styles.col}>
          {transactionDetails?.from_user?.uid === undefined || transactionDetails?.from_user?.uid === null ? "---" : transactionDetails?.from_user?.uid}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>To</div>
        <div className={styles.col}>
          {transactionDetails?.to_user?.uid === undefined || transactionDetails?.to_user?.uid === null ? "---" : transactionDetails?.to_user?.uid}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Debited Amount(With Fee)</div>
        <div className={styles.col}>
          SRD {parseFloat(transactionDetails?.amount) + parseFloat(transactionDetails?.wallet_transaction_fees?.debited_fee)}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Credited Amount</div>
        <div className={styles.col}>
          SRD {parseFloat(transactionDetails?.total_amount)}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Deducted Fee from Sender side</div>
        <div className={styles.col}>
          SRD {parseFloat(transactionDetails?.wallet_transaction_fees?.debited_fee)}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Deducted Fee from Receiver side</div>
        <div className={styles.col}>
          SRD {parseFloat(transactionDetails?.wallet_transaction_fees?.credited_fee)}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Total Fee(Profit)</div>
        <div className={styles.col}>
          SRD {parseFloat(transactionDetails?.wallet_transaction_fees?.debited_fee) + parseFloat(transactionDetails?.wallet_transaction_fees?.credited_fee)}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Transaction Id</div>
        <div className={styles.col}>
          {transactionDetails?.txId}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Status</div>
        <div className={styles.col}>
          {transactionDetails?.status === parseInt(process.env.REACT_APP_DEPOSIT_STATUS_PENDING) ?
            <div className={cn("status-red-dark", styles.distribution)}>
              Pending
            </div>
            :
            transactionDetails?.status === parseInt(process.env.REACT_APP_DEPOSIT_STATUS_SUCCESS) ?
              <div className={cn("status-green-dark", styles.distribution)}>
                Success
              </div>
              :
              <div className={cn("status-red-dark", styles.distribution)}>
                Declined
              </div>
          }
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Date</div>
        <div className={styles.col}>
          {toLocaleStringDateFormat(transactionDetails?.created_at)}
        </div>
      </div>
    </div>
  );
};

export default NewPost;

import React, { useState } from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Modal from "../../../components/Modal";
import Row from "./Row";
import Form from "./Form";
import UpdateForm from "./UpdateForm";
import LoaderScreen from "../../../components/LoaderScreen";

const RecentPost = ({
  className,
  referralData,
  loading,
  addReferralHandler,
  visibleModal,
  setVisibleModal,
  updateReferralHandler,
  setUpdateCategoryModal,
  updateCategoryModal
}) => {

  const [selectedReferral, setselectedReferral] = useState([]);

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Recently Added"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <button
              className={cn("button-small", styles.button)}
              onClick={() => setVisibleModal(true)}
            >
              Add New Referral Bonus
            </button>
          </>
        }
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>#</div>
            <div className={styles.col}>Name</div>
            <div className={styles.col}>Amount</div>
            <div className={styles.col}>Type</div>
            <div className={styles.col}>Status</div>
            <div className={styles.col}>Date</div>
            <div className={styles.col}>Action</div>
          </div>
          {
            loading ? <LoaderScreen /> :
              referralData?.length != 0 ?
                referralData?.filter((y) => {
                  return y?.type === "referral"
                }).map((x, index) => (
                  <Row
                    item={x}
                    key={index}
                    index={index}
                    setUpdateCategoryModal={setUpdateCategoryModal}
                    setselectedReferral={setselectedReferral}
                  />
                ))
                :
                "No data found"
          }
        </div>
      </Card>
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <Form
          addReferralHandler={addReferralHandler}
        />
      </Modal>

      <Modal
        outerClassName={styles.outer}
        visible={updateCategoryModal}
        onClose={() => setUpdateCategoryModal(false)}
      >
        <UpdateForm
          selectedReferral={selectedReferral}
          updateReferralHandler={updateReferralHandler}
        />
      </Modal>
    </>
  );
};

export default RecentPost;

import React from "react";
import styles from "./Row.module.sass";
import cn from "classnames";

const Row = ({
  item,
  actionHandler,
}) => {

  function capitalizeFirstLetter(inputString) {
    return inputString.charAt(0).toUpperCase() + inputString.slice(1);
  };

  return (
    <div className={styles.row}>
      <div className={styles.col}>
        {item?.low}
      </div>
      <div className={styles.col}>
        {item?.medium}
      </div>
      <div className={styles.col}>
        {item?.high}
      </div>
      <div className={styles.col}>
        <div className={styles.cell}>
          <div className={styles.box}>
            {item?.duration}
          </div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.cell}>
          <div className={styles.box}>
            {capitalizeFirstLetter(item?.interval)}
          </div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.cell}>
          <div className={styles.box}>
            {item?.module === "cashrecharge" ? "Cash Recharge" : capitalizeFirstLetter(item?.module)}
          </div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.cell}>
          <div className={styles.box}>
            {parseInt(item?.status) === parseInt(process.env.REACT_APP_REFERRAL_STATUS_DISABLE) ?
              <div className={cn("status-red-dark", styles.distribution)}>
                Disable
              </div>
              :
              <div className={cn("status-green-dark", styles.distribution)}>
                Enable
              </div>}
          </div>
        </div>
      </div>
      <div className={styles.col}>
        {parseInt(item?.status) === parseInt(process.env.REACT_APP_REFERRAL_STATUS_ENABLE) ?
          <div className={styles.row}>
            <button className={cn("button-small", styles.rejectButton)} type="button"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                actionHandler(item?.id, parseInt(process.env.REACT_APP_REFERRAL_STATUS_DISABLE));
              }}>
              <span>Disable</span>
            </button>
          </div> :
          parseInt(item?.status) === parseInt(process.env.REACT_APP_REFERRAL_STATUS_DISABLE) ?
            <div className={styles.row}>
              <button className={cn("button-small", styles.acceptButton)} type="button"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  actionHandler(item?.id, parseInt(process.env.REACT_APP_REFERRAL_STATUS_ENABLE));
                }}>
                <span>Enable</span>
              </button>
            </div> :
            ""
        }
      </div>
    </div>
  );
};

export default Row;

import React, { useState } from "react";
import cn from "classnames";
import styles from "./Form.module.sass";
import LoaderScreen from "../../components/LoaderScreen";
import TextInput from "../../components/TextInput";
import Card from "../../components/Card";
import Dropdown from "../../components/Dropdown";
import requestHandler from "../../actions/httpClient";
import { addNotification } from "../../components/Notification";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const intervals = ["Transaction Fee", "Cash Recharge", "Bank Recharge"];

const Form = ({ className }) => {
  const [sorting, setSorting] = useState(intervals[0]);
  const [debitFee, setDebitFee] = useState("");
  const [creditFee, setCreditFee] = useState("");
  const [depositFee, setDepositFee] = useState("");
  const [withdrawFee, setWithdrawFee] = useState("");
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [cashRechargeFee, setCashRechargeFee] = useState("");

  const addFeeHandler = async () => {
    setLoading(true);
    let data = {};
    if (sorting.includes("Bank")) {
      data = {
        type: parseInt(process.env.REACT_APP_OTHER_FEE),
        withdraw_fee: withdrawFee,
        deposit_fee: depositFee,
        cashrecharge_fee: 0,
      };
    }
    else if (sorting.includes("Cash")) {
      data = {
        type: parseInt(process.env.REACT_APP_CASH_RECHARGE_FEE),
        cashrecharge_fee: cashRechargeFee,
      };
    }
    else {
      data = {
        type: parseInt(process.env.REACT_APP_TRANSACTION_FEE),
        credit_fee: creditFee,
        debit_fee: debitFee,
        cashrecharge_fee: 0,
      };
    }
    try {
      const addFeePayload = await requestHandler("transaction/fee", "post", data, "jwt_token");
      setLoading(false);
      if (addFeePayload && addFeePayload.status === 200) {
        addNotification({
          title: "Success",
          message: addFeePayload?.data?.message,
          type: "success",
        });
        history.push("/fee");
      }
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: e?.data?.message,
        type: "danger",
      });
    }
  };

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Transaction Fee"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
      >
        <div className={styles.share}>
          {loading && <LoaderScreen />}
          <div className={styles.description}>
            <div className={styles.customField}>
              <Dropdown
                label="Transaction Type"
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={sorting}
                setValue={setSorting}
                options={intervals}
                small
              />
            </div>
          </div>
          {sorting.toLowerCase() === "bank recharge" &&
            <div className={styles.description}>
              <div className={styles.customField}>
                <TextInput
                  className={styles.field}
                  label="Deposit Fee"
                  name="depositFee"
                  type="text"
                  onChange={(e) => setDepositFee(e.target.value)}
                  placeholder="Please enter deposit fee"
                />
              </div>
              <div className={styles.customField}>
                <TextInput
                  className={styles.field}
                  label="Withdraw Fee"
                  name="withdrawFee"
                  type="text"
                  onChange={(e) => setWithdrawFee(e.target.value)}
                  placeholder="Please enter withdraw fee"
                />
              </div>
            </div>
          }

          {sorting.toLowerCase() === "cash recharge" &&
            <div className={styles.description}>
              <div className={styles.customField}>
                <TextInput
                  className={styles.field}
                  label="Cash Recharge Fee"
                  name="cashRechargeFee"
                  type="text"
                  onChange={(e) => setCashRechargeFee(e.target.value)}
                  placeholder="Please enter cash recharge fee"
                />
              </div>
            </div>
          }

          {sorting.toLowerCase() == "transaction fee" &&
            <div className={styles.description}>
              <div className={styles.customField}>
                <TextInput
                  className={styles.field}
                  label="Debit Fee"
                  name="debitFee"
                  type="text"
                  onChange={(e) => setDebitFee(e.target.value)}
                  placeholder="Please enter debit fee"
                />
              </div>
              <div className={styles.customField}>
                <TextInput
                  className={styles.field}
                  label="Credit Fee"
                  name="creditFee"
                  type="text"
                  onChange={(e) => setCreditFee(e.target.value)}
                  placeholder="Please enter credit fee"
                />
              </div>
            </div>
          }
          <button className={cn("button button-small", styles.button)}
            onClick={(e) => {
              e.stopPropagation();
              if (sorting.toLowerCase() == "transaction fee") {
                if (debitFee != "" && creditFee != "") {
                  addFeeHandler();
                }
                else {
                  addNotification({
                    title: "Alert",
                    message: "Please fill all the fields.",
                    type: "danger",
                  });
                };
              }
              else if (sorting.toLowerCase() == "cash recharge") {
                if (cashRechargeFee != "") {
                  addFeeHandler();
                }
                else {
                  addNotification({
                    title: "Alert",
                    message: "Please fill all the fields.",
                    type: "danger",
                  });
                };
              }
              else {
                if (depositFee != "" && withdrawFee != "") {
                  addFeeHandler();
                }
                else {
                  addNotification({
                    title: "Alert",
                    message: "Please fill all the fields.",
                    type: "danger",
                  });
                }
              }
            }}>
            Submit
          </button>
        </div>
      </Card>
    </>
  );
};

export default Form;

import React, { useRef, useState } from "react";
import styles from "./Details.module.sass";
import cn from "classnames";
import SimpleReactValidator from "simple-react-validator";
import TextInput from "../../../../../components/TextInput";
import Onfido from "../../../../../components/Onfido";

const Details = ({ getUserDetailsHandler, addDirectorHandler, token, workFlowRunId, applicantId }) => {
  const simpleValidator = useRef(new SimpleReactValidator())
  const [, forceUpdate] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [countryCode, setCountryCode] = useState(597);
  const [phoneNumber, setPhoneNumber] = useState();
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <>
      {activeIndex === 0 &&
        (<div className={styles.details}>
          <div className={cn("title-purple", styles.title)}>Add Director</div>
          <div className={styles.description}>
            <div className={styles.customField}>
              <TextInput
                className={styles.field}
                label="First Name"
                name="firstName"
                type="text"
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="Please enter first name"
                required
                onBlur={() => simpleValidator.current.showMessageFor("firstName")}
              />
              {simpleValidator.current.message("firstName", firstName, "required")}
            </div>
            <div className={styles.customField}>
              <TextInput
                className={styles.field}
                label="Last Name"
                name="lastName"
                type="text"
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Please enter last name"
                required
                onBlur={() => simpleValidator.current.showMessageFor("lastName")}
              />
              {simpleValidator.current.message("lastName", lastName, "required")}
            </div>
            <div className={styles.customField}>
              <TextInput
                className={styles.field}
                label="Email"
                name="email"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Please enter email"
                required
                onBlur={() => simpleValidator.current.showMessageFor("email")}
              />
              {simpleValidator.current.message("email", email, "required|email")}
            </div>
            <div className={styles.customField}>
              <TextInput
                className={styles.field}
                label="Country Code"
                name="countryCode"
                type="text"
                placeholder="Country Number"
                required
                icon="mobile"
                disabled={true}
                value={countryCode}
              />
            </div>
            <div className={styles.customField}>
              <TextInput
                className={styles.field}
                label="Contact Number"
                name="contactNumber"
                type="text"
                placeholder="Contact Number"
                required
                value={phoneNumber}
                onChange={(e) => { setPhoneNumber(e.target.value) }}
                onBlur={() => simpleValidator.current.showMessageFor("phoneNumber")}
              />
              {simpleValidator.current.message("phone", phoneNumber, "required")}
            </div>
          </div>
          <div className={styles.control}>
            <button className={cn("button", styles.button)} onClick={(e) => {
              e.preventDefault();
              if (simpleValidator.current.allValid()) {
                addDirectorHandler(firstName, lastName, email, phoneNumber, countryCode, setActiveIndex);
              } else {
                simpleValidator.current.showMessages();
                forceUpdate(1);
              }
            }
            }>
              <span>Continue</span>
            </button>
          </div>
        </div>)
      }
      {activeIndex === 1 && (
        <Onfido
          token={token}
          workFlowRunId={workFlowRunId}
          applicantId={applicantId}
          getUserDetailsHandler={getUserDetailsHandler}
        />
      )}

    </>
  );
};

export default Details;

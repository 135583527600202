import React from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Form from "../../../components/Form";
import Row from "./Row";
import CutomerListSkeleton from "../../../components/Skeleton/CutomerListSkeleton";

const RecentPost = ({
  className,
  setSearchField,
  searchField,
  loading,
  usersList,
  searchCustomerHandler,
  status
}) => {

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title={status === parseInt(process.env.REACT_APP_MERCHANT_ROLE) ? "Merchant" : "User"}
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <Form
              className={styles.form}
              value={searchField}
              setValue={setSearchField}
              onSubmit={handleSubmit}
              placeholder="Search by UId or email"
              type="text"
              name="search"
              icon="search"
            />
            <div className={styles.control}>
              <button className={cn("button", styles.button)} onClick={() => { searchCustomerHandler(); }}>
                <span>Submit</span>
              </button>
            </div>
          </>
        }
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>C Id</div>
            <div className={styles.col}>Customer</div>
            <div className={styles.col}>U Id</div>
            <div className={styles.col}>KYC Status</div>
            <div className={styles.col}>Bank Status</div>
            <div className={styles.col}>Residence Status</div>
            <div className={styles.col}>Registration Date</div>
          </div>
          {
            loading ? <CutomerListSkeleton colCount={7} rowCount={12} /> :
              usersList?.map((x, index) => (
                <Row
                  item={x}
                  key={index}
                />
              ))
          }
        </div>
      </Card>
    </>
  );
};

export default RecentPost;

import React from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import { toLocaleStringDateFormat } from "../../../../components/helper";
import { Link } from "react-router-dom";
const Row = ({ item, setVisibleModal, setTransactionDetails }) => {
  return (
    <div
      className={styles.row}
      onClick={() => {
        // setVisibleModal(true);
        setTransactionDetails(item);
      }}
    >
      <div className={styles.col}>{item?.parent_id}</div>
      <Link
        className={styles.col}
        to={{
          pathname: `/sub-users/profile`,
          // pathname: `/user-profile/${item?.id}`,
          state: item,
        }}
      >
        <div className={styles.text}>{item?.id}</div>
      </Link>
      <Link
        className={styles.col}
        to={{
          pathname: `/sub-users/profile`,
          // pathname: `/user-profile/${item?.id}`,
          state: item,
        }}
      >
        <div className={styles.text}>
          {item?.first_name === undefined ||
          item?.first_name === null ||
          item?.last_name === null ||
          item?.last_name === undefined
            ? "---"
            : `${item?.first_name} ${item?.last_name}`}
          <div className={styles.text}>
            {item?.email === undefined || item?.email === null
              ? "---"
              : item?.email}
          </div>
        </div>
      </Link>
      <div className={styles.col}>{item?.uid}</div>
      <div className={styles.col}>
        {toLocaleStringDateFormat(item?.created_at)}
      </div>
    </div>
  );
};

export default Row;

import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Promote.module.sass";
import RecentPost from "./RecentPost";
import requestHandler from "../../actions/httpClient";
import { addNotification } from "../../components/Notification";

const Promote = () => {

  const [loading, setLoading] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [pageRefreshFlag, setPageRefreshFlag] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [updateCategoryModal, setUpdateCategoryModal] = useState(false);

  useEffect(() => {
    getCategoriesDatHandler();
  }, [pageRefreshFlag]);

  const getCategoriesDatHandler = async () => {
    setLoading(true);
    try {
      const categoriesDataPayload = await requestHandler("category/index", "get", "", "jwt_token");
      setLoading(false);
      setCategoryData(categoriesDataPayload?.data?.data);
    }
    catch (e) {
      setLoading(false);
    }
  };

  const addCategoryHandler = async (name, icon, status, cashbackPercentage, transactionFee, creditTransactionFee) => {
    setLoading(true);
    let data = {
      name: name,
      icon: icon,
      status: status.toLowerCase() === "enable" ? parseInt(process.env.REACT_APP_CATEGORY_ENABLE) : parseInt(process.env.REACT_APP_CATEGORY_DISABLE),
      cashback: cashbackPercentage,
      debit_transaction_fee: transactionFee,
      credit_transaction_fee: creditTransactionFee,
    };
    try {
      const addCategoryPayload = await requestHandler("category/create", "post", data, "jwt_token", "file");
      setLoading(false);
      if (addCategoryPayload && addCategoryPayload.status === 200) {
        addNotification({
          title: "Success",
          message: addCategoryPayload?.data?.message,
          type: "success"
        });
      }
      setPageRefreshFlag(!pageRefreshFlag);
      setVisibleModal(false);
    }
    catch (e) {
      addNotification({
        title: "Alert",
        message: e?.data?.error?.name[0],
        type: "danger"
      });
      setPageRefreshFlag(!pageRefreshFlag);
      setLoading(false);
    };
  };

  const updateCategoryHandler = async (id, status, categoryName, categoryImage, cashbackPercentage, transactionFee, type, creditTransactionFee) => {
    setLoading(true);
    let data = {
      id: id,
      status: status.toLowerCase() === "enable" ? parseInt(process.env.REACT_APP_CATEGORY_ENABLE) : parseInt(process.env.REACT_APP_CATEGORY_DISABLE),
      name: categoryName,
      cashback: cashbackPercentage,
      debit_transaction_fee: transactionFee,
      icon: categoryImage,
      type: type,
      credit_transaction_fee: creditTransactionFee,
    };
    try {
      const updateCategoryPayload = await requestHandler("category/update", "post", data, "jwt_token", "file");
      setLoading(false);
      if (updateCategoryPayload && updateCategoryPayload?.status === 200) {
        addNotification({
          title: "Success",
          message: updateCategoryPayload?.data?.message,
          type: "success"
        });
        setUpdateCategoryModal(false);
        setPageRefreshFlag(!pageRefreshFlag);
        getCategoriesDatHandler();
      }
    }
    catch (e) {
      setLoading(false);
      setPageRefreshFlag(!pageRefreshFlag);
      addNotification({
        title: "Error",
        message: e?.data?.error,
        type: "danger"
      });
    };
  };

  return (
    <>
      <div className={styles.section}>
        <RecentPost
          categoryData={categoryData}
          loading={loading}
          pageCount={pageCount}
          setPageCount={setPageCount}
          addCategoryHandler={addCategoryHandler}
          visibleModal={visibleModal}
          setVisibleModal={setVisibleModal}
          updateCategoryHandler={updateCategoryHandler}
          updateCategoryModal={updateCategoryModal}
          setUpdateCategoryModal={setUpdateCategoryModal}
        />
      </div>
    </>
  );
};

export default Promote;

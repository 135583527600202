import React from "react";
import cn from "classnames";
import styles from "./Item.module.sass";
import Icon from "../../../../components/Icon";
import { getDigitsAfterDecimal } from "../../../../components/helper";

const Item = ({ className, onActive, item }) => {
  return (
    <div className={cn(styles.item, className)} onClick={onActive}>
      <div className={styles.icon} style={{ backgroundColor: item.color }}>
        <Icon name={item.icon} size="24" />
      </div>
      {item.hasOwnProperty('user_count') && (
        <div className={styles.details}>
          <div className={styles.subtitle}>
            All Users
          </div>
          <div className={styles.counter}>{item.user_count}</div>
        </div>
      )}
      {item.hasOwnProperty('fee_count') && (
        <div className={styles.details}>
          <div className={styles.subtitle}>
            Total Profit
          </div>
          <div className={styles.counter}>SRD {getDigitsAfterDecimal(item.fee_count, 2)}</div>
        </div>
      )}
    </div>
  );
};

export default Item;

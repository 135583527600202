import React, { useRef, useState } from "react";
import cn from "classnames";
import styles from "./Form.module.sass";
import SimpleReactValidator from "simple-react-validator";
import LoaderScreen from "../../../../components/LoaderScreen";
import Dropdown from "../../../../components/Dropdown";
import TextInput from "../../../../components/TextInput";

const intervals = ["Enable", "Disable"];

const Form = ({
  loading,
  selectedCategory,
  updateCategoryHandler
}) => {
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, setForceUpdate] = useState();
  const [sorting, setSorting] = useState(selectedCategory?.status === parseInt(process.env.REACT_APP_CATEGORY_ENABLE) ? intervals[0] : intervals[1]);
  const [categoryName, setCategoryName] = useState(selectedCategory?.name);
  const [categoryImage, setCategoryImage] = useState(null);
  const [cashbackPercentage, setCashbackPercentage] = useState(selectedCategory?.cashback);
  const [transactionFee, setTransactionFee] = useState(selectedCategory?.debit_transaction_fee);
  const [creditTransactionFee, setCreditTransactionFee] = useState(selectedCategory?.credit_transaction_fee);

  return (
    <div className={styles.share}>
      {loading && <LoaderScreen />}
      <div className={styles.head}>
        <div className={cn("title-red", styles.title)}>Update Category</div>
      </div>
      <TextInput
        className={styles.field}
        label="Category Name"
        name="categoryName"
        type="text"
        placeholder="Please enter a value"
        value={categoryName}
        onChange={(e) => { setCategoryName(e.target.value) }}
        required
      />
      <span className={styles.errorMessage}>
        {simpleValidator.current.message("categoryName", categoryName, "required")}
      </span>
      <TextInput
        className={styles.field}
        label="Category Image"
        name="categoryImage"
        type="file"
        onChange={(e) => { setCategoryImage(e.target.files[0]) }}
        required
      />
      {/* <span className={styles.errorMessage}>
        {simpleValidator.current.message("categoryImage", categoryImage, "required")}
      </span> */}
      <TextInput
        className={styles.field}
        label="Cashback Percentage"
        name="cashbackPercentage"
        type="text"
        value={cashbackPercentage}
        placeholder="Please enter a value"
        onChange={(e) => { setCashbackPercentage(e.target.value) }}
        required
      />
      <span className={styles.errorMessage}>
        {simpleValidator.current.message("cashbackPercentage", cashbackPercentage, "required")}
      </span>
      <TextInput
        className={styles.field}
        label="Debit Transaction Fee"
        name="debitTransactionFee"
        type="text"
        value={transactionFee}
        placeholder="Please enter a value"
        onChange={(e) => { setTransactionFee(e.target.value) }}
        required
      />
      <span className={styles.errorMessage}>
        {simpleValidator.current.message("debitTransactionFee", transactionFee, "required")}
      </span>
      <TextInput
        className={styles.field}
        label="Credit Transaction Fee"
        name="creditTransactionFee"
        type="text"
        placeholder="Please enter a value"
        onChange={(e) => { setCreditTransactionFee(e.target.value) }}
        required
        value={creditTransactionFee}
      />
      <span className={styles.errorMessage}>
        {simpleValidator.current.message("creditTransactionFee", creditTransactionFee, "required")}
      </span>
      <Dropdown
        label="Status"
        className={styles.dropdown}
        classDropdownHead={styles.dropdownHead}
        value={sorting}
        setValue={setSorting}
        options={intervals}
        small
      />
      <button className={cn("button button-small", styles.button)}
        onClick={(e) => {
          e.stopPropagation();
          if (simpleValidator.current.allValid()) {
            updateCategoryHandler(selectedCategory?.id, sorting, categoryName, categoryImage ? categoryImage : selectedCategory?.icon, cashbackPercentage, transactionFee, categoryImage ? "no" : "yes", creditTransactionFee);
          }
          else {
            simpleValidator.current.showMessages();
            setForceUpdate(1);
          }
        }}>
        <span>Save</span>
      </button>
    </div>
  );
};

export default Form;

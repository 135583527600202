import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Promote.module.sass";
import RecentPost from "./RecentPost";
import requestHandler from "../../actions/httpClient";
import { addNotification } from "../../components/Notification";

const Promote = () => {

  const [loading, setLoading] = useState(false);
  const [subUsersData, setSubUsersData] = useState([]);
  const [searchField, setSearchField] = useState("");
  const [visibleModal, setVisibleModal] = useState(false);

  useEffect(() => {
    getSubUserDataHandler();
  }, []);

  const getSubUserDataHandler = async () => {
    setLoading(true);
    try {
      const getsubUsersDataPayload = await requestHandler("user/sub-users", "get", "", "jwt_token");
      setLoading(false);
      setSubUsersData(getsubUsersDataPayload?.data[0]);
    }
    catch (e) {
      setLoading(false);
    }
  };

  const searchCustomerHandler = async () => {
    setLoading(true);
    if (searchField === "") {
      setLoading(false);
      addNotification({
        title: "Alert",
        message: "Please enter email or UId",
        type: "danger"
      });
    }
    else {
      let data = {
        value: searchField,
      };
      try {
        const getsubUsersDataPayload = await requestHandler("user/user-filter", "post", data, "jwt_token");
        setLoading(false);
        setSubUsersData(getsubUsersDataPayload?.data[0]);
      }
      catch (e) {
        setLoading(false);
        addNotification({
          title: "Error",
          message: e?.data?.error,
          type: "danger",
        });
      }
    }
  };

  return (
    <>
      <div className={styles.section}>
        <RecentPost
          loading={loading}
          subUsersData={subUsersData}
          searchField={searchField}
          setSearchField={setSearchField}
          visibleModal={visibleModal}
          setVisibleModal={setVisibleModal}
          searchCustomerHandler={searchCustomerHandler}
        />
      </div>
    </>
  );
};

export default Promote;

import React from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Row from "./Row";
import { Link } from "react-router-dom/cjs/react-router-dom";
import Icon from "../../../components/Icon";
import CustomerListSkeleton from "../../../components/Skeleton/CutomerListSkeleton";

const RecentPost = ({
  className,
  feeData,
  loading
}) => {

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Recent post"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <Link
              className={cn("button-small", styles.button)}
              to="/add-fee"
            >
              <Icon name="add"></Icon>
              Add Fee
            </Link>
          </>
        }
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>#</div>
            <div className={styles.col}>Type</div>
            <div className={styles.col}>Cash Recharge Fee</div>
            <div className={styles.col}>Credit Fee</div>
            <div className={styles.col}>Debit Fee</div>
            <div className={styles.col}>Deposit Fee</div>
            <div className={styles.col}>Withdraw Fee</div>
            <div className={styles.col}>Date</div>
          </div>
          {loading ? <CustomerListSkeleton colCount={7} rowCount={12} /> :
            feeData?.length !== 0 ?
              feeData?.map((x, index) => (
                <Row
                  item={x}
                  key={index}
                  index={index} />
              ))
              :
              "No data found"
          }
        </div>
      </Card>
    </>
  );
};

export default RecentPost;

import React, { useState } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import Modal from "../../../../../components/Modal";
import DirectorDetails from "../DirectorDetails";

const Row = ({
  item,
  directorDetails,
  getDirectorDetails,
  updateOnfidoHandler,
  setOnfidoWindowModal,
}) => {
  const [detailsModal, setDetailsModal] = useState(false);

  return (
    <>
      <div className={styles.row} onClick={() => {
        setDetailsModal(true);
        getDirectorDetails(item?.id);
      }}>
        <div className={styles.col}>
          {item?.director_first_name === undefined || item?.director_first_name === null ? "---" : item?.director_first_name}
        </div>
        <div className={styles.col}>
          {item?.director_last_name === undefined || item?.director_last_name === null ? "---" : item?.director_last_name}
        </div>
        <div className={styles.col}>
          {item?.email === undefined || item?.email === null ? "---" : item?.email}
        </div>
        <div className={styles.col}>
          {item?.phone === undefined || item?.phone === null ? "---" : `+(${item?.country_code})${item?.phone}`}
        </div>
        <div className={styles.col}>
          {
            parseInt(item?.status) === parseInt(process.env.REACT_APP_KYC_STATUS_DECLINED) ?
              <div className={cn("status-red-dark", styles.distribution)}>
                Declined
              </div>
              :
              parseInt(item?.status) === parseInt(process.env.REACT_APP_KYC_STATUS_PENDING) ?
                <div className={cn(styles.distribution)}>
                  Pending
                </div>
                :
                parseInt(item?.status) === parseInt(process.env.REACT_APP_KYC_STATUS_COMPLETED) ?
                  <div className={cn(styles.distribution)}>
                    Completed
                  </div>
                  :
                  parseInt(item?.status) === parseInt(process.env.REACT_APP_KYC_STATUS_INPROCESS) ?
                    <div className={cn(styles.distribution)}>
                      In-Process
                    </div>
                    :
                    parseInt(item?.status) === parseInt(process.env.REACT_APP_KYC_STATUS_ABANDONED) ?
                      <div className={cn(styles.distribution)}>
                        Abadoned
                      </div>
                      :
                      parseInt(item?.status) === parseInt(process.env.REACT_APP_KYC_STATUS_ERROR) ?
                        <div className={cn(styles.distribution)}>
                          Error
                        </div>
                        :
                        parseInt(item?.status) === parseInt(process.env.REACT_APP_KYC_STATUS_REVIEW) ?
                          <div className={cn(styles.distribution)}>
                            In-Review
                          </div>
                          :
                          parseInt(item?.status) === parseInt(process.env.REACT_APP_KYC_STATUS_UNVERIFIED) ?
                            <div className={cn(styles.distribution)}>
                              Unverified
                            </div>
                            :
                            ""
          }
        </div>
        <div className={styles.col}>
          {
            parseInt(item?.status) === parseInt(process.env.REACT_APP_KYC_STATUS_PENDING) ?
              <button className={cn("button button-small", styles.button, styles.acceptButton)}
                onClick={(e) => {
                  e.stopPropagation();
                  updateOnfidoHandler(item?.businesskyc?.applicant_kyc_id);
                  setOnfidoWindowModal(true);
                }}>
                <span>Start KYC</span>
              </button>
              :
              <button className={cn("button button-small", styles.button, styles.acceptButton)}
                onClick={(e) => { e.stopPropagation(); window.open(process.env.REACT_APP_ONFIDO_REPORT_URL + "/" + item.businesskyc.workflow_run_id, '_blank', 'noopener,noreferrer') }}>
                <span>View KYC</span>
              </button>
          }
        </div>
      </div >
      <Modal
        visible={detailsModal}
        onClose={() => setDetailsModal(false)}
      >
        <DirectorDetails
          directorDetails={directorDetails}
        />
      </Modal>
    </>
  );
};

export default Row;

import React from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../../components/Card";
import Row from "./Row";

const RecentPost = ({ className, userData }) => {

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Referrals"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>#</div>
            <div className={styles.col}>UId</div>
            <div className={styles.col}>Status</div>
            {/* <div className={styles.col}>Status</div> */}
            <div className={styles.col}>Date</div>
          </div>
          {
            userData?.referral?.length > 0 ?
              userData?.referral?.map((x, index) => (
                <Row item={x} key={index} index={index} />
              ))
              :
              <div>
                No Data Found
              </div>
          }
        </div>
      </Card>
    </>
  );
};

export default RecentPost;

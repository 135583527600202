import React, { useState } from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Row from "./Row";
import CutomerListSkeleton from "../../../components/Skeleton/CutomerListSkeleton";
import NewPost from "./NewPost";
import Modal from "../../../components/Modal";
import CustomDropdown from "../../../components/CustomDropdown";

const RecentPost = ({
  className,
  usersList,
  loading,
  updateRequestHandler,
  statusText,
  setStatusText,
  statusValue,
  setStatusValue,
  intervals
}) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [userDetails, setUserDetails] = useState([]);

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Recent Requests"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <div className={styles.dropdown}>
              <CustomDropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={statusValue}
                setValue={setStatusValue}
                text={statusText}
                setText={setStatusText}
                options={intervals}
                small
              />
            </div>
          </>
        }
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>ID</div>
            <div className={styles.col}>Customer</div>
            <div className={styles.col}>UId</div>
            <div className={styles.col}>Role</div>
            <div className={styles.col}>Status</div>
            <div className={styles.col}>Date</div>
            {statusText.toLowerCase() === "pending" && <div className={styles.col}>Action</div>}
          </div>
          {loading ? (
            <CutomerListSkeleton colCount={7} rowCount={12} />
          ) : (
            usersList?.map((item, index) => (
              <Row
                item={item}
                key={index}
                updateRequestHandler={updateRequestHandler}
                statusText={statusText}
                setVisibleModal={setVisibleModal}
                setUserDetails={setUserDetails}
              />
            ))
          )}
        </div>
      </Card>
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <NewPost
          userDetails={userDetails}
        />
      </Modal>
    </>
  );
};

export default RecentPost;

import React from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import { Link } from "react-router-dom";
import { toLocaleStringDateFormat } from "../../../../components/helper";

const Row = ({ item, updateKycStatus }) => {
  return (
    <div className={styles.row}>
      <div className={styles.col}>{item?.id}</div>
      <Link
        className={styles.col}
        to={{
          pathname: `/user-profile`,
          // pathname: `/user-profile/${item?.id}`,
          state: item,
        }}
      >
        {item?.first_name === undefined ||
          item?.first_name === null ||
          item?.last_name === null ||
          item?.last_name === undefined
          ? "---"
          : `${item?.first_name} ${item?.last_name}`}
        <div className={styles.text}>{item?.email}</div>
      </Link>
      <div className={styles.col}>{item?.uid}</div>
      <div className={styles.col}>
        {item?.kyc_verification === parseInt(process.env.REACT_APP_KYC_STATUS_DECLINED) ? (
          <div className={cn("status-red-dark", styles.distribution)}>
            Declined
          </div>
        ) : item?.kyc_verification ===
          parseInt(process.env.REACT_APP_KYC_STATUS_PENDING) ? (
          <div className={cn("status-red-dark", styles.distribution)}>
            Pending
          </div>
        ) : item?.kyc_verification ===
          parseInt(process.env.REACT_APP_KYC_STATUS_COMPLETED) ? (
          <div className={cn("status-green-dark", styles.distribution)}>
            Completed
          </div>
        ) : item?.kyc_verification ===
          parseInt(process.env.REACT_APP_KYC_STATUS_INPROCESS) ? (
          <div className={cn("status-red-dark", styles.distribution)}>
            In-Process
          </div>
        ) : item?.kyc_verification ===
          parseInt(process.env.REACT_APP_KYC_STATUS_ABANDONED) ? (
          <div className={cn("status-red-dark", styles.distribution)}>
            Abadoned
          </div>
        ) : item?.kyc_verification ===
          parseInt(process.env.REACT_APP_KYC_STATUS_ERROR) ? (
          <div className={cn("status-red-dark", styles.distribution)}>
            Error
          </div>
        ) : item?.kyc_verification ===
          parseInt(process.env.REACT_APP_KYC_STATUS_REVIEW) ? (
          <div className={cn("status-red-dark", styles.distribution)}>
            In-Review
          </div>
        ) : item?.kyc_verification ===
          parseInt(process.env.REACT_APP_KYC_STATUS_UNVERIFIED) ? (
          <div className={cn("status-red-dark", styles.distribution)}>
            Unverified
          </div>
        ) : (
          ""
        )}
      </div>
      {/* <div className={styles.col}>
        {
          item?.status === parseInt(process.env.REACT_APP_UNVERIFIED_EMAIL_CUSTOMERS) ?
            <div className={cn("status-red-dark", styles.distribution)}>
              Unverified
            </div>
            :
            item?.status === parseInt(process.env.REACT_APP_VERIFIED_EMAIL_CUSTOMERS) ?
              <div className={cn("status-green-dark", styles.distribution)}>
                Activate
              </div>
              :
              item?.status === parseInt(process.env.REACT_APP_FREEZED_EMAIL_CUSTOMERS) ?
                <div className={cn("status-red-dark", styles.distribution)}>
                  Freezed
                </div>
                :
                ""
        }
      </div> */}
      <div className={styles.col}>
        {item?.bank_status ===
          parseInt(process.env.REACT_APP_BANK_STATUS_PENDING) ? (
          <div className={cn("status-red-dark", styles.distribution)}>
            Pending
          </div>
        ) : item?.bank_status ===
          parseInt(process.env.REACT_APP_BANK_STATUS_REJECT) ? (
          <div className={cn("status-red-dark", styles.distribution)}>
            Rejected
          </div>
        ) : item?.bank_status ===
          parseInt(process.env.REACT_APP_BANK_STATUS_ACCEPT) ? (
          <div className={cn("status-green-dark", styles.distribution)}>
            Accepted
          </div>
        ) : (
          ""
        )}
      </div>
      <div className={styles.col}>
        {item?.residence_status ===
          parseInt(process.env.REACT_APP_RESIDENCE_ACTIVE) ? (
          <div className={cn("status-green-dark", styles.distribution)}>
            Active
          </div>
        ) : item?.residence_status ===
          parseInt(process.env.REACT_APP_RESIDENCE_INACTIVE) ? (
          <div className={cn("status-red-dark", styles.distribution)}>
            In-Active
          </div>
        ) : (
          ""
        )}
      </div>

      <div className={styles.col}>
        {toLocaleStringDateFormat(item?.created_at)}
      </div>
      {item?.kyc_verification ===
        parseInt(process.env.REACT_APP_KYC_STATUS_INPROCESS) ||
        item?.kyc_verification ===
        parseInt(process.env.REACT_APP_KYC_STATUS_REVIEW) ? (
        <div className={styles.col}>
          <div className={styles.row}>
            <div>
              <button
                className={cn("button-small", styles.acceptButton)}
                type="button"
                onClick={(e) => {
                  updateKycStatus(
                    item?.id,
                    parseInt(process.env.REACT_APP_KYC_STATUS_COMPLETED)
                  );
                }}
              >
                <span>Approve</span>
              </button>
              <button
                className={cn("button-small", styles.rejectButton)}
                type="button"
                onClick={(e) => {
                  updateKycStatus(
                    item?.id,
                    parseInt(process.env.REACT_APP_KYC_STATUS_DECLINED)
                  );
                }}
              >
                <span>Decline</span>
              </button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Row;

import React from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import { Link } from "react-router-dom";
import { toLocaleStringDateFormat } from "../../../../components/helper";

const Row = ({ item, status, updateKycHandler }) => {
  return (
    <div className={styles.row}>
      <div className={styles.col}>{item?.id}</div>
      <Link
        className={styles.col}
        to={{
          pathname: `/merchant-profile`,
          state: item,
        }}
      >
        {item?.bussiness_name === undefined || item?.bussiness_name === null
          ? "---"
          : item?.bussiness_name}
        <div className={styles.text}>{item?.email}</div>
      </Link>
      <div className={styles.col}>{item?.uid}</div>
      <div className={styles.col}>
        {item?.kyc_verification ===
        parseInt(process.env.REACT_APP_KYC_STATUS_DECLINED) ? (
          <div className={cn("status-red-dark", styles.distribution)}>
            Declined
          </div>
        ) : item?.kyc_verification ===
          parseInt(process.env.REACT_APP_KYC_STATUS_PENDING) ? (
          <div className={cn("status-red-dark", styles.distribution)}>
            Pending
          </div>
        ) : item?.kyc_verification ===
          parseInt(process.env.REACT_APP_KYC_STATUS_COMPLETED) ? (
          <div className={cn("status-green-dark", styles.distribution)}>
            Completed
          </div>
        ) : item?.kyc_verification ===
          parseInt(process.env.REACT_APP_KYC_STATUS_INPROCESS) ? (
          <div className={cn("status-red-dark", styles.distribution)}>
            In-Process
          </div>
        ) : item?.kyc_verification ===
          parseInt(process.env.REACT_APP_KYC_STATUS_ABANDONED) ? (
          <div className={cn("status-red-dark", styles.distribution)}>
            Abadoned
          </div>
        ) : item?.kyc_verification ===
          parseInt(process.env.REACT_APP_KYC_STATUS_ERROR) ? (
          <div className={cn("status-red-dark", styles.distribution)}>
            Error
          </div>
        ) : item?.kyc_verification ===
          parseInt(process.env.REACT_APP_KYC_STATUS_REVIEW) ? (
          <div className={cn("status-red-dark", styles.distribution)}>
            In-Review
          </div>
        ) : item?.kyc_verification ===
          parseInt(process.env.REACT_APP_KYC_STATUS_UNVERIFIED) ? (
          <div className={cn("status-red-dark", styles.distribution)}>
            Unverified
          </div>
        ) : (
          ""
        )}
      </div>
      <div className={styles.col}>
        {item?.bank_status ===
        parseInt(process.env.REACT_APP_BANK_STATUS_PENDING) ? (
          <div className={cn("status-red-dark", styles.distribution)}>
            Pending
          </div>
        ) : item?.bank_status ===
          parseInt(process.env.REACT_APP_BANK_STATUS_REJECT) ? (
          <div className={cn("status-red-dark", styles.distribution)}>
            Reject
          </div>
        ) : item?.bank_status ===
          parseInt(process.env.REACT_APP_BANK_STATUS_ACCEPT) ? (
          <div className={cn("status-green-dark", styles.distribution)}>
            Accept
          </div>
        ) : (
          ""
        )}
      </div>
      <div className={styles.col}>
        {item?.residence_status ===
        parseInt(process.env.REACT_APP_RESIDENCE_ACTIVE) ? (
          <div className={cn("status-red-dark", styles.distribution)}>
            Active
          </div>
        ) : item?.residence_status ===
          parseInt(process.env.REACT_APP_RESIDENCE_INACTIVE) ? (
          <div className={cn("status-red-dark", styles.distribution)}>
            Inactive
          </div>
        ) : (
          ""
        )}
      </div>
      <div className={styles.col}>
        {toLocaleStringDateFormat(item?.updated_at)}
      </div>
      {parseInt(status) ===
        parseInt(process.env.REACT_APP_KYC_STATUS_INPROCESS) && (
        <div className={styles.col}>
          <div className={styles.row}>
            <button
              className={cn("button-small", styles.acceptButton)}
              type="button"
              onClick={(e) => {
                e.preventDefault();
                updateKycHandler(
                  item?.id,
                  parseInt(process.env.REACT_APP_KYC_STATUS_COMPLETED)
                );
              }}
            >
              <span>Approve</span>
            </button>
            <button
              className={cn("button-small", styles.rejectButton)}
              type="button"
              onClick={(e) => {
                e.preventDefault();
                updateKycHandler(
                  item?.id,
                  parseInt(process.env.REACT_APP_KYC_STATUS_DECLINED)
                );
              }}
            >
              <span>Reject</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Row;

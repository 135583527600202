import React from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../../components/Card";
import Row from "./Row";
import NewPost from "./NewPost";
import Modal from "../../../../components/Modal";

const RecentPost = ({
  className,
  visibleModal,
  setVisibleModal,
  transactionDetails,
  setTransactionDetails,
  transactionData,
}) => {
  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Fiat Withdraw "
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>Deposited Amount</div>
            <div className={styles.col}>Transaction Id</div>
            <div className={styles.col}>Reference Number</div>
            <div className={styles.col}>Status</div>
            <div className={styles.col}>Date</div>
          </div>
          {transactionData
            ?.filter((y) => {
              return y.type === "withdraw";
            })
            ?.map((x, index) => (
              <Row
                item={x}
                key={index}
                setVisibleModal={setVisibleModal}
                setTransactionDetails={setTransactionDetails}
              />
            ))}
        </div>
      </Card>
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <NewPost transactionDetails={transactionDetails} />
      </Modal>
    </>
  );
};

export default RecentPost;

import React from "react";
import styles from "./NewPost.module.sass";
import cn from "classnames";
import { toLocaleStringDateFormat } from "../../../../components/helper";

const NewPost = ({ transactionDetails }) => {

  return (
    <div className={styles.share}>
      <div className={styles.head}>
        <div className={cn("title-red", styles.title)}>Sub User Details</div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Parent Id</div>
        <div className={styles.col}>
          {transactionDetails?.parent_id}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>C Id</div>
        <div className={styles.col}>
          {transactionDetails?.id}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Name</div>
        <div className={styles.col}>
          {(transactionDetails?.full_name === null || transactionDetails?.full_name === undefined) ? "---" : transactionDetails?.full_name}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Email</div>
        <div className={styles.col}>
          {(transactionDetails?.email === null || transactionDetails?.email === undefined) ? "---" : transactionDetails?.email}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>U Id</div>
        <div className={styles.col}>
          {transactionDetails?.uid}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Date</div>
        <div className={styles.col}>
          {toLocaleStringDateFormat(transactionDetails?.created_at)}
        </div>
      </div>
    </div>
  );
};

export default NewPost;

import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Promote.module.sass";
import RecentPost from "./RecentPost";
import requestHandler from "../../../actions/httpClient";
const intervals = ["All", "Debited", "Credited", "Cashrecharge"];
const Promote = ({ userData, state, setloading }) => {
  const [transactionData, setTransactionData] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [transactionDetails, setTransactionDetails] = useState([]);
  const [sorting, setSorting] = useState(intervals[0]);

  useEffect(() => {
    getTransactionList();
  }, []);

  const getTransactionList = async () => {
    setloading(true);
    let data = {
      user_id: state?.id,
    };
    try {
      const getTransactionListPayload = await requestHandler(
        "user/user-transaction",
        "post",
        data,
        "jwt_token"
      );
      setloading(false);
      setTransactionData(getTransactionListPayload?.data?.data);
    } catch (e) {
      setloading(false);
    }
  };

  return (
    <>
      <div className={styles.section}>
        <RecentPost
          userData={userData}
          transactionData={transactionData}
          visibleModal={visibleModal}
          setVisibleModal={setVisibleModal}
          transactionDetails={transactionDetails}
          setTransactionDetails={setTransactionDetails}
          sorting={sorting}
          setSorting={setSorting}
          intervals={intervals}
        />
      </div>
    </>
  );
};

export default Promote;

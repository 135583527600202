import React, { useRef, useState } from "react";
import cn from "classnames";
import styles from "./Form.module.sass";
import SimpleReactValidator from "simple-react-validator";
import LoaderScreen from "../../../../components/LoaderScreen";
import CustomDropdown from "../../../../components/CustomDropdown";
import TextInput from "../../../../components/TextInput";

const intervals = [
  { "cId": parseInt(process.env.REACT_APP_REFERRAL_STATUS_ENABLE), "cValue": "Enable" },
  { "cId": parseInt(process.env.REACT_APP_REFERRAL_STATUS_DISABLE), "cValue": "Disable" }
];

const Form = ({
  loading,
  selectedReferral,
  updateReferralHandler
}) => {
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, setForceUpdate] = useState();
  const [referralStatusText, setReferralStatusText] = useState(selectedReferral?.status === parseInt(process.env.REACT_APP_REFERRAL_STATUS_ENABLE) ? intervals[0].cValue : intervals[1].cValue);
  const [referralStatusValue, setReferralStatusValue] = useState(selectedReferral?.status === parseInt(process.env.REACT_APP_REFERRAL_STATUS_ENABLE) ? intervals[0].cId : intervals[1].cId);
  const [referralName, setReferralName] = useState(selectedReferral?.name);
  const [referralAmount, setReferralAmount] = useState(selectedReferral?.amount);

  return (
    <div className={styles.share}>
      {loading && <LoaderScreen />}
      <div className={styles.head}>
        <div className={cn("title-red", styles.title)}>Update :- {selectedReferral.name}</div>
      </div>
      <TextInput
        className={styles.field}
        label="Name"
        name="bonusName"
        type="text"
        placeholder="Please enter a value"
        value={referralName}
        onChange={(e) => { setReferralName(e.target.value) }}
        required
      />
      <span className={styles.errorMessage}>
        {simpleValidator.current.message("bonusName", referralName, "required")}
      </span>
      <TextInput
        className={styles.field}
        label="Amount"
        name="bonusAmount"
        type="text"
        value={referralAmount}
        placeholder="Please enter a value"
        onChange={(e) => {
          const re = /^-?\d*\.?\d*$/;
          if (e.target.value === '' || re.test(e.target.value)) {
            setReferralAmount(e.target.value)
          }
        }}
        required
      />
      <span className={styles.errorMessage}>
        {simpleValidator.current.message("bonusAmount", referralAmount, "required")}
      </span>
      <CustomDropdown
        label="Status"
        className={styles.dropdown}
        classDropdownHead={styles.dropdownHead}
        value={referralStatusValue}
        setValue={setReferralStatusValue}
        text={referralStatusText}
        setText={setReferralStatusText}
        options={intervals}
      />
      <button className={cn("button button-small", styles.button)}
        onClick={(e) => {
          e.stopPropagation();
          if (simpleValidator.current.allValid()) {
            updateReferralHandler(selectedReferral?.id, referralStatusValue, referralName, referralAmount);
          }
          else {
            simpleValidator.current.showMessages();
            setForceUpdate(1);
          }
        }}>
        <span>Save</span>
      </button>
    </div>
  );
};

export default Form;

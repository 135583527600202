import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Promote.module.sass";
import RecentPost from "./RecentPost";
import requestHandler from "../../actions/httpClient";
import { addNotification } from "../../components/Notification";

const intervals = [
  { "cId": parseInt(process.env.REACT_APP_DEPOSIT_STATUS_PENDING), "cValue": "Pending" },
  { "cId": parseInt(process.env.REACT_APP_DEPOSIT_STATUS_SUCCESS), "cValue": "Completed" }
];

const Promote = ({ }) => {
  const [loading, setLoading] = useState(true);
  const [usersList, setUsersList] = useState([]);
  const [statusValue, setStatusValue] = useState(intervals[0].cId);
  const [statusText, setStatusText] = useState(intervals[0].cValue);

  useEffect(() => {
    getFreezedRequestHandler();
  }, [statusValue]);

  const getFreezedRequestHandler = async () => {
    setLoading(true);
    let data = {
      status: statusValue
    };
    try {
      const getFreezedRequestPayload = await requestHandler("list-account-freeze-request", "post", data, "jwt_token");
      setUsersList(getFreezedRequestPayload?.data?.data);
      setLoading(false);
    }
    catch (e) {
      setLoading(false);
    };
  };

  const updateRequestHandler = async (id, status) => {
    setLoading(true);
    let data = {
      user_id: id,
      status: status,
    };
    try {
      const updateRequestPayload = await requestHandler("update-account-freeze-request", "post", data, "jwt_token");
      setLoading(false);
      if (updateRequestPayload.status === 200) {
        addNotification(
          {
            title: "Success",
            message: updateRequestPayload?.data?.message,
            type: "success",
          }
        );
        getFreezedRequestHandler();
      }
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: e?.data?.error,
        type: "danger"
      });
    };
  };

  return (
    <>
      <div className={styles.section}>
        <RecentPost
          usersList={usersList}
          loading={loading}
          updateRequestHandler={updateRequestHandler}
          statusText={statusText}
          setStatusText={setStatusText}
          statusValue={statusValue}
          setStatusValue={setStatusValue}
          intervals={intervals}
        />
      </div>
    </>
  );
};

export default Promote;

import React from "react";
import styles from "./Row.module.sass";
import cn from "classnames";

const Row = ({ item, updateBankDetailsHandler, userId }) => {

  return (
    <div className={styles.row}>
      <div className={styles.col}>
        {item?.account_number}
      </div>
      <div className={styles.col} onClick={() => { window.open(item?.bank_proof, '_blank', 'noopener,noreferrer') }}>
        <img className={styles.imgClass} src={item?.bank_proof} alt="proof" />
      </div>
      <div className={styles.col}>
        {
          item?.status === parseInt(process.env.REACT_APP_BANK_STATUS_PENDING) ?
            <div className={cn("status-red-dark", styles.distribution)}>
              Pending
            </div>
            :
            item?.status === parseInt(process.env.REACT_APP_BANK_STATUS_REJECT) ?
              <div className={cn("status-red-dark", styles.distribution)}>
                Reject
              </div>
              :
              item?.status === parseInt(process.env.REACT_APP_BANK_STATUS_ACCEPT) ?
                <div className={cn("status-green-dark", styles.distribution)}>
                  Accept
                </div>
                :
                ""
        }
      </div>
      {item?.status === parseInt(process.env.REACT_APP_BANK_STATUS_PENDING) ?
        <div className={styles.col}>
          <button
            className={cn("button-small", styles.button, styles.acceptButton)}
            onClick={() => { updateBankDetailsHandler(userId, item?.id, parseInt(process.env.REACT_APP_BANK_STATUS_ACCEPT)) }}
          >
            Accept
          </button>
          <button
            className={cn("button-small", styles.button, styles.rejectButton)}
            onClick={() => { updateBankDetailsHandler(userId, item?.id, parseInt(process.env.REACT_APP_BANK_STATUS_REJECT)) }}
          >
            Reject
          </button>
        </div>
        :
        item?.status === parseInt(process.env.REACT_APP_BANK_STATUS_REJECT) ?
          <div className={styles.col}>
            <button
              className={cn("button-small", styles.button, styles.acceptButton)}
              onClick={() => { updateBankDetailsHandler(userId, item?.id, parseInt(process.env.REACT_APP_BANK_STATUS_ACCEPT)) }}
            >
              Accept
            </button>
          </div>
          :
          item?.status === parseInt(process.env.REACT_APP_BANK_STATUS_ACCEPT) ?
            <div className={styles.col}>
              <button
                className={cn("button-small", styles.button, styles.rejectButton)}
                onClick={() => { updateBankDetailsHandler(userId, item?.id, parseInt(process.env.REACT_APP_BANK_STATUS_REJECT)) }}
              >
                Reject
              </button>
            </div> : ""
      }
      {/* <div className={styles.col}>
        <button
          className={cn("button-small", styles.button, styles.acceptButton)}
          onClick={() => { updateBankDetailsHandler(userId, item?.id, parseInt(process.env.REACT_APP_BANK_STATUS_ACCEPT)) }}
        >
          Accept
        </button>
        <button
          className={cn("button-small", styles.button, styles.rejectButton)}
          onClick={() => { updateBankDetailsHandler(userId, item?.id, parseInt(process.env.REACT_APP_BANK_STATUS_REJECT)) }}
        >
          Reject
        </button>
      </div> */}
    </div>
  );
};

export default Row;

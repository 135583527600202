import React, { useState } from "react";
import cn from "classnames";
import styles from "./SignUp.module.sass";
import { use100vh } from "react-div-100vh";
import { Link } from "react-router-dom";
import Entry from "./Entry";
import Code from "./Code";
import Barcode from "./Barcode";
import Confirm from "./Confirm";
import Image from "../../components/Image";
import requestHandler from "../../actions/httpClient";
import axios from "axios";
import { addNotification } from "../../components/Notification";
import { useHistory } from 'react-router-dom';
import LoaderScreen from "../../components/LoaderScreen";

const items = [
  "Unlimited product uploads",
  "Pro tips",
  "Free forever",
  "Full author options",
];

const SignUp = () => {
  const [visible, setVisible] = useState(true);
  const heightWindow = use100vh();
  const [activeIndex, setActiveIndex] = useState(0);
  const [captchaData, setCaptchaData] = useState([]);
  const [captchaField, setCaptchaField] = useState("");
  const [captchaLoading, setCaptchaLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [userId, setUserId] = useState("");

  const loginHandler = async (email, password) => {
    setLoading(true);
    let data = {
      email: email,
      password: password,
      role: parseInt(process.env.REACT_APP_ADMIN_ROLE),
    }
    try {
      const loginPayload = await requestHandler("admin-login", "post", data, "jwt_token");
      setLoading(false);
      if (loginPayload && loginPayload.status === 200) {
        localStorage.setItem("token", loginPayload?.data?.token);
        localStorage.setItem("id", loginPayload?.data?.user?.id);
        history.push("/");
        addNotification({
          title: "Success",
          message: loginPayload?.data?.message,
          type: "success"
        });
      }
      else if (loginPayload && loginPayload.status === 201) {
        setUserId(loginPayload?.data?.user_id);
        setActiveIndex(1);
      }
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: e?.data?.error,
        type: "danger"
      });
    }
  };

  const codeScreenHandler = async (firstField, secondField, thirdField, fourthField, fifthField, sixthField) => {
    setLoading(true);
    let data = {
      user_id: userId,
      otp: firstField + secondField + thirdField + fourthField + fifthField + sixthField,
    };
    try {
      const codeScreenPayload = await requestHandler("verify-otp", "post", data, "jwt_token");
      setLoading(false);
      if (codeScreenPayload && codeScreenPayload.status === 200) {
        addNotification({
          title: "Success",
          message: codeScreenPayload?.data?.message,
          type: "success"
        });
        localStorage.setItem("token", codeScreenPayload?.data?.token);
        localStorage.setItem("id", codeScreenPayload?.data?.user?.id);
        history.push("/");
      }
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: e?.data?.error,
        type: "danger"
      });
    };
  };

  return (
    <div className={styles.row}>
      <div className={styles.col}>
        <div className={styles.wrap}>
          <div className={styles.preview}>
            <img src="/images/logo-light.svg" alt="Login" />
          </div>
          <div className={cn("h4", styles.subtitle)}>Administration
          </div>
          <ul className={styles.list}>
            {items.map((x, index) => (
              <li key={index}>{x}</li>
            ))}
          </ul>
        </div>
      </div>
      <div className={styles.col} style={{ minHeight: heightWindow }}>
        {loading && <LoaderScreen />}
        <div className={styles.wrapper}>
          <div className={cn("h2", styles.title)}>Sign In</div>
          {activeIndex === 0 &&
            <Entry
              loginHandler={loginHandler}
            />
          }
          {/* {visible ? <Entry onConfirm={() => setVisible(false)} /> : <Code />} */}
          {/* {activeIndex === 0 && <Entry
            setActiveIndex={setActiveIndex}
            captchaData={captchaData}
            captchaField={captchaField}
            setCaptchaField={setCaptchaField}
            captchaLoading={captchaLoading}
            loginHandler={loginHandler}
          />} */}
          {/* {activeIndex === 1 && <Barcode />} */}
          {/* {activeIndex === 1 && <Barcode />} */}
          {activeIndex === 1 &&
            <Code
              codeScreenHandler={codeScreenHandler}
            />}
          {/* {activeIndex === 3 && <Confirm />} */}
          {/* {activeIndex === 3 && <Confirm />} */}
        </div>
      </div>
    </div>
  );
};

export default SignUp;

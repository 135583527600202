import React from "react";
import cn from "classnames";
import styles from "./ProfileInformation.module.sass";
import Item from "../Item";
import TextInput from "../../../components/TextInput";
import { addNotification } from "../../../components/Notification";

const ProfileInformation = ({
  className,
  userData,
  setUserData,
  updateUserHandler,
}) => {
  return (
    <Item
      className={cn(styles.card, className)}
      title="Basic information"
      classTitle="title-green"
    >
      <div className={styles.fieldset}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Customer Id"
            name="customerId"
            type="customerId"
            required
            disabled={true}
            value={
              userData?.id === undefined || userData?.id === null
                ? "---"
                : userData?.id
            }
          />
          <TextInput
            className={styles.field}
            label="User Id"
            name="userId"
            type="userId"
            required
            disabled={true}
            value={
              userData?.uid === null || userData?.uid === undefined
                ? "---"
                : userData?.uid
            }
          />
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="First Name"
            name="firstName"
            required
            value={
              userData?.first_name === undefined || userData === null
                ? "---"
                : userData?.first_name
            }
            onChange={(e) => {
              setUserData((userData) => ({
                ...userData,
                first_name: e.target.value,
              }));
            }}
          />
          <TextInput
            className={styles.field}
            label="Last Name"
            name="lastName"
            required
            value={
              userData?.last_name === null || userData?.last_name === undefined
                ? "---"
                : userData?.last_name
            }
            onChange={(e) => {
              setUserData((userData) => ({
                ...userData,
                last_name: e.target.value,
              }));
            }}
          />
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Email"
            name="email"
            required
            disabled={true}
            value={
              userData?.email === null || userData?.email === undefined
                ? "---"
                : userData?.email
            }
          />
          <TextInput
            className={styles.field}
            label="Phone"
            name="phone"
            required
            disabled={true}
            value={
              userData?.phone === null || userData?.phone === undefined
                ? "---"
                : `(${userData?.country})${userData?.phone}`
            }
          />
        </div>
      </div>
      <button
        onClick={(e) => {
          e.preventDefault();
          if (userData?.name !== "") {
            updateUserHandler(userData);
          } else {
            addNotification({
              title: "Alert",
              message: "Name field can't be empty.",
              type: "danger",
            });
          }
        }}
        className={cn("button-small", styles.button)}
      >
        Save Changes
      </button>
    </Item>
  );
};

export default ProfileInformation;

import React from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import { toLocaleStringDateFormat, getDigitsAfterDecimal } from "../../../../components/helper";

const Row = ({
  item,
  setVisibleModal,
  setTransactionDetails,
  status,
  updateTransactionHandler,
}) => {

  return (
    <div className={styles.row} onClick={() => {
      setVisibleModal(true);
      setTransactionDetails(item);
    }}>
      <div className={styles.col}>
        SRD {getDigitsAfterDecimal(parseFloat(item?.amount), 2)}
      </div>
      <div className={styles.col}>
        <div className={styles.cell}>
          <div className={styles.box}>
            <div className={styles.number}>
              {item?.txId}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.cell}>
          <div className={styles.box}>
            <div className={styles.number}>
              {item?.payment_reference === null || item?.payment_reference === undefined ? "NA" : item?.payment_reference}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.col}>
        {parseInt(item?.status) === parseInt(process.env.REACT_APP_DEPOSIT_STATUS_PENDING) ?
          <div className={cn("status-red-dark", styles.distribution)}>Pending</div> :
          parseInt(item?.status) === parseInt(process.env.REACT_APP_DEPOSIT_STATUS_SUCCESS) ?
            <div className={cn("status-green-dark", styles.distribution)}>Success</div> :
            <div className={cn("status-red-dark", styles.distribution)}>Declined</div>
        }
      </div>
      <div className={styles.col}>
        {toLocaleStringDateFormat(item?.created_at)}
      </div>
      {parseInt(status) === parseInt(process.env.REACT_APP_DEPOSIT_STATUS_PENDING) &&
        <div className={styles.col}>
          <div className={styles.row}>
            <div>
              <button className={cn("button", styles.acceptButton)} type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  updateTransactionHandler(item?.id, parseInt(process.env.REACT_APP_DEPOSIT_STATUS_SUCCESS), item?.from_user?.id, item?.total_amount, item?.wallet_transaction_fees?.deposit_fee);
                }}
              >
                <span>Approve</span>
              </button>
              <button className={cn("button", styles.rejectButton)} type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  updateTransactionHandler(item?.id, parseInt(process.env.REACT_APP_DEPOSIT_STATUS_DECLINED), item?.from_user?.id, item?.total_amount, item?.wallet_transaction_fees?.deposit_fee);
                }}
              >
                <span>Decline</span>
              </button>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default Row;

import { useDispatch, useSelector } from "react-redux";
import styles from "./Create.module.sass";
import Form from './Form';
import { useEffect } from "react";
import { userDataCreator } from "../../actions/getUserStatus";

const ChangePassword = () => {

    const { userStatus } = useSelector((state) => { return state.getUserData });
    const dispatch = useDispatch();

    useEffect(() => {
        if (userStatus?.length === 0) {
            dispatch(userDataCreator());
        }
    }, []);

    return (
        <div className={styles.customClass}>
            <Form
                userStatus={userStatus}
            />
        </div>
    )
};

export default ChangePassword;
import React from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Modal from "../../../components/Modal";
import Row from "./Row";
import NewPost from "./NewPost";

const RecentPost = ({
  className,
  transactionData,
  addTMValuesHandler,
  visibleModal,
  setVisibleModal,
  actionHandler
}) => {

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Recent Values"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <button
              className={cn("button-small", styles.button)}
              onClick={() => setVisibleModal(true)}
            >
              Add New Value
            </button>
          </>
        }
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>Low</div>
            <div className={styles.col}>Medium</div>
            <div className={styles.col}>High</div>
            <div className={styles.col}>Duration</div>
            <div className={styles.col}>Interval</div>
            <div className={styles.col}>Module</div>
            <div className={styles.col}>Status</div>
            <div className={styles.col}>Action</div>
          </div>
          {transactionData?.map((x, index) => (
            <Row
              item={x}
              key={index}
              actionHandler={actionHandler}
            />
          ))}
        </div>
      </Card>
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <NewPost
          addTMValuesHandler={addTMValuesHandler}
        />
      </Modal>
    </>
  );
};

export default RecentPost;

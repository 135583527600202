import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Promote.module.sass";
import RecentPost from "./RecentPost";
import requestHandler from "../../actions/httpClient";
import { addNotification } from "../../components/Notification";

const Promote = ({ status }) => {

  const [loading, setLoading] = useState(false);
  const [transactionData, setTransactionData] = useState([]);

  useEffect(() => {
    getTransactionsData();
  }, []);

  const getTransactionsData = async () => {
    setLoading(true);
    let data = {
      status: parseInt(status),
    };
    try {
      const getTransactionDataPayload = await requestHandler("withdraw/index", "post", data, "jwt_token");
      setLoading(false);
      setTransactionData(getTransactionDataPayload?.data?.data);
    }
    catch (e) {
      setLoading(false);
    }
  };

  const updateTransactionHandler = async (id, status) => {
    setLoading(true);
    let data = {
      id: id,
      status: status,
    };
    try {
      const updateTransactionPayload = await requestHandler("withdraw/update-balance", "post", data, "jwt_token");
      setLoading(false);
      if (updateTransactionPayload.status === 200) {
        addNotification({
          title: "Success",
          message: updateTransactionPayload?.data?.message,
          type: "success",
        });
        getTransactionsData();
      }
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: "Something went wrong.",
        type: "danger",
      });
    };
  };

  return (
    <>
      <div className={styles.section}>
        <RecentPost
          status={status}
          loading={loading}
          transactionData={transactionData}
          updateTransactionHandler={updateTransactionHandler}
        />
      </div>
    </>
  );
};

export default Promote;

import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Promote.module.sass";
import RecentPost from "./RecentPost";
import requestHandler from "../../../actions/httpClient";

const Promote = ({ state, loading, setloading, userData }) => {

  const [visibleModal, setVisibleModal] = useState(false);
  const [transactionDetails, setTransactionDetails] = useState([]);
  const [transactionData, setTransactionData] = useState([]);

  useEffect(() => {
    getTransactionList();
  }, []);

  const getTransactionList = async () => {
    setloading(true);
    let data = {
      user_id: state?.id,
    };
    try {
      const getTransactionListPayload = await requestHandler("user/user-transaction", "post", data, "jwt_token");
      setloading(false);
      setTransactionData(getTransactionListPayload?.data?.data);
    }
    catch (e) {
      setloading(false);
    };
  };

  return (
    <>
      <div className={styles.section}>
        <RecentPost
          userData={userData}
          visibleModal={visibleModal}
          setVisibleModal={setVisibleModal}
          transactionDetails={transactionDetails}
          setTransactionDetails={setTransactionDetails}
          transactionData={transactionData}
        />
      </div>
    </>
  );
};

export default Promote;

import React from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import { toLocaleStringDateFormat, getDigitsAfterDecimal } from "../../../../../components/helper";

const Row = ({ item }) => {

  return (
    <div className={styles.row}>
      <div className={styles.col}>
        SRD {getDigitsAfterDecimal(item?.amount, 2)}
      </div>
      <div className={styles.col}>
        {item?.type === "referral" || item?.type === "signup" ? "---" : `${item?.cashback_percentage}%`}
      </div>
      <div className={styles.col}>
        {item?.suripay_txn_id}
      </div>
      <div className={styles.col}>
        <div className={cn("status-green-dark", styles.distribution)}>
          Success
        </div>
      </div>
      {/* <div className={styles.col}>
        <div className={cn("status-green-dark", styles.distribution)}>
          Success
        </div>
      </div> */}
      <div className={styles.col}>
        {item?.type?.toUpperCase()}
      </div>
      <div className={styles.col}>
        {toLocaleStringDateFormat(item?.created_at)}
      </div>
    </div>
  );
};

export default Row;

import React, { useState } from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Row from "./Row";
import CustomerListSkeleton from "../../../components/Skeleton/CutomerListSkeleton";
import Form from "../../../components/Form";
import Modal from "../../../components/Modal";
import NewPost from "./NewPost";
import Dropdown from "../../../components/Dropdown";

const RecentPost = ({
  className,
  loading,
  transactionData,
  handleSubmit,
  setSearchField,
  searchField,
  setVisibleModal,
  visibleModal,
  intervals,
  sorting,
  setSorting,
}) => {
  const [transactionDetails, setTransactionDetails] = useState([]);

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Recent transactions"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <Form
              className={styles.form}
              value={searchField}
              setValue={setSearchField}
              onSubmit={handleSubmit}
              placeholder="Search by transaction id"
              type="text"
              name="search"
              icon="search"
            />
            <div className={styles.dropdown}>
              <Dropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={sorting}
                setValue={setSorting}
                options={intervals}
                small
              />
            </div>
          </>
        }
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>From</div>
            <div className={styles.col}>To</div>

            <div className={styles.col}>Credited Amount</div>
            <div className={styles.col}>Transaction Id</div>
            <div className={styles.col}>Transaction Type</div>
            <div className={styles.col}>Status</div>
            <div className={styles.col}>Date</div>
          </div>
          {loading ? (
            <CustomerListSkeleton colCount={6} rowCount={12} />
          ) : transactionData?.length !== 0 ? (
            transactionData
              ?.filter((x) => {
                return x?.txId?.includes(searchField);
              })
              // .filter((y) => {
              //   return y.type === "transaction"
              // })
              .map((x, index) => (
                <Row
                  item={x}
                  key={index}
                  visibleModal={visibleModal}
                  setVisibleModal={setVisibleModal}
                  setTransactionDetails={setTransactionDetails}
                />
              ))
          ) : (
            "No data found"
          )}
        </div>
        {/* <div className={styles.foot}>
          <button className={cn("button-stroke button-small", styles.button)}>
            <Loader className={styles.loader} />
            <span>Load more</span>
          </button>
        </div> */}
      </Card>
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <NewPost transactionDetails={transactionDetails} sorting={sorting} />
      </Modal>
    </>
  );
};

export default RecentPost;

import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Promote.module.sass";
import RecentPost from "./RecentPost";
import requestHandler from "../../actions/httpClient";
import LoaderScreen from "../../components/LoaderScreen";
import { addNotification } from "../../components/Notification";

const Promote = () => {
  const [loading, setLoading] = useState(false);
  const [transactionData, setTransactionData] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);

  useEffect(() => {
    getTMValuesHandler();
  }, []);

  const getTMValuesHandler = async () => {
    setLoading(true);
    try {
      const getTMListPayload = await requestHandler("transactionMonitorValue/index", "get", "jwt_token");
      setLoading(false);
      setTransactionData(getTMListPayload?.data?.data);
    }
    catch (e) {
      setLoading(false);
    }
  };

  const addTMValuesHandler = async (module, low, medium, high, interval, duration) => {
    setLoading(true);
    let data = {
      low: low,
      medium: medium,
      high: high,
      module: module === "Cash Recharge" ? "cashrecharge" : module?.toLowerCase(),
      duration: duration,
      interval: interval.toLowerCase(),
    };
    try {
      const addTMValuesPayload = await requestHandler("transactionMonitorValue/create", "post", data, "jwt_token");
      setLoading(false);
      if (addTMValuesPayload && addTMValuesPayload.status === 200) {
        addNotification({
          title: "Success",
          message: addTMValuesPayload?.data?.message,
          type: "success",
        });
        getTMValuesHandler();
        setVisibleModal(false);
      }
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: e?.data?.error,
        type: "danger",
      });
    };
  };

  const actionHandler = async (id, status) => {
    setLoading(true);
    let data = {
      id: id,
      status: status
    };
    try {
      const actionPayload = await requestHandler("transactionMonitorValue/update", "post", data, "jwt_token");
      setLoading(false);
      if (actionPayload && actionPayload.status === 200) {
        addNotification({
          title: "Success",
          message: actionPayload?.data?.message,
          type: "success"
        });
      }
      getTMValuesHandler();
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: "Something went wrong, Please try again later.",
        type: "danger",
      });
    };
  };

  return (
    <>
      {loading && <LoaderScreen />}
      <div className={styles.section}>
        <RecentPost
          loading={loading}
          transactionData={transactionData}
          addTMValuesHandler={addTMValuesHandler}
          visibleModal={visibleModal}
          setVisibleModal={setVisibleModal}
          actionHandler={actionHandler}
        />
      </div>
    </>
  );
};

export default Promote;

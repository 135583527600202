import React from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import { toLocaleStringDateFormat, getDigitsAfterDecimal } from "../../../../components/helper";

const Row = ({ item, index }) => {

  return (
    <div className={styles.row}>
      <div className={styles.col}>
        {index + 1}
      </div>
      <div className={styles.col}>
        {
          parseInt(item?.type) === parseInt(process.env.REACT_APP_TRANSACTION_FEE) ? "Transaction" :
            parseInt(item?.type) === parseInt(process.env.REACT_APP_CASH_RECHARGE_FEE) ? "Cash Recharge" :
              parseInt(item?.type) === parseInt(process.env.REACT_APP_OTHER_FEE) ? "Bank Recharge" :
                ""
        }
      </div>
      <div className={styles.col}>
        {item?.cashrecharge_fee === undefined || item?.cashrecharge_fee === null || item?.cashrecharge_fee === "0.00" ? "---" : getDigitsAfterDecimal(item?.cashrecharge_fee, 2)}
      </div>
      <div className={styles.col}>
        {item?.credit_fee === undefined || item?.credit_fee === null ? "---" : getDigitsAfterDecimal(item?.credit_fee, 2)}
      </div>
      <div className={styles.col}>
        {item?.debit_fee === undefined || item?.debit_fee === null ? "---" : getDigitsAfterDecimal(item?.debit_fee, 2)}
      </div>
      <div className={styles.col}>
        {item?.deposit_fee === undefined || item?.deposit_fee === null ? "---" : getDigitsAfterDecimal(item?.deposit_fee, 2)}
      </div>
      <div className={styles.col}>
        {item?.withdraw_fee === undefined || item?.withdraw_fee === null ? "---" : getDigitsAfterDecimal(item?.withdraw_fee, 2)}
      </div>
      <div className={styles.col}>
        {toLocaleStringDateFormat(item?.updated_at)}
      </div>
    </div>
  );
};

export default Row;

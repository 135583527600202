import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Promote.module.sass";
import RecentPost from "./RecentPost";
import requestHandler from "../../actions/httpClient";
import LoaderScreen from "../../components/LoaderScreen";
import { addNotification } from "../../components/Notification";

const tmStatus = ["Pending", "Complete"];
const intervalValues = ["Single", "Days"];

const Promote = () => {
  const [loading, setLoading] = useState(false);
  const [transactionData, setTransactionData] = useState();
  const [intervalValue, setIntervalValue] = useState(tmStatus[0]);
  const [interval, setInterval] = useState(intervalValues[0]);

  useEffect(() => {
    getTMValuesList();
  }, [intervalValue, interval]);

  const getTMValuesList = async () => {
    setLoading(true);
    let data = {
      interval: interval.toLowerCase() === "days" ? "hour" : interval.toLowerCase(),
      status: intervalValue.toLowerCase() === "pending" ? parseInt(process.env.REACT_APP_TRANSACTION_MONITORING_STATUS_PENDING)
        : parseInt(process.env.REACT_APP_TRANSACTION_MONITORING_STATUS_COMPLETE),
    };
    try {
      const getTMPayload = await requestHandler("transactionMonitorValue/transaction-list", "post", data, "jwt_token");
      setLoading(false);
      setTransactionData(getTMPayload?.data);
    }
    catch (e) {
      setLoading(false);
    };
  };

  const updateTMHandler = async (id) => {
    setLoading(true);
    let data = {
      id: id,
    };
    try {
      const updateTMPayload = await requestHandler("transactionMonitorValue/update-transaction-status", "post", data, "jwt_token");
      setLoading(false);
      if (updateTMPayload?.status === 200) {
        addNotification({
          title: "Sucess",
          message: updateTMPayload?.data?.message,
          type: "success",
        });
        getTMValuesList();
      }
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: "Something went wrong, please try again later.",
        type: "danger",
      });
    };
  };

  return (
    <>
      {loading && <LoaderScreen />}
      <div className={styles.section}>
        <RecentPost
          transactionData={transactionData}
          intervalValue={intervalValue}
          setIntervalValue={setIntervalValue}
          tmStatus={tmStatus}
          updateTMHandler={updateTMHandler}
          interval={interval}
          setInterval={setInterval}
          intervalValues={intervalValues}
        />
      </div>
    </>
  );
};

export default Promote;

import React, { useState } from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";
import Modal from "../../../components/Modal";
import Row from "./Row";
import Details from "../RecentPost/Row/Details";

const RecentPost = ({
  className,
  transactionData,
  intervalValue,
  setIntervalValue,
  tmStatus,
  updateTMHandler,
  interval,
  setInterval,
  intervalValues
}) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState([]);

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Recent Transactions"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <div className={styles.customDropdown}>
              <Dropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={intervalValue}
                setValue={setIntervalValue}
                options={tmStatus}
                small
              />
            </div>
            <div className={styles.customDropdown}>
              <Dropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={interval}
                setValue={setInterval}
                options={intervalValues}
                small
              />
            </div>
          </>
        }
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>User Id</div>
            <div className={styles.col}>Module</div>
            <div className={styles.col}>Amount</div>
            <div className={styles.col}>Interval</div>
            {intervalValue.toLowerCase() === "completed" && <div className={styles.col}>Checked By</div>}
            <div className={styles.col}>Date</div>
            {intervalValue.toLowerCase() === "pending" && <div className={styles.col}>Action</div>}
          </div>
          {
            transactionData?.length !== 0 ?
              transactionData?.map((x, index) => (
                <Row
                  item={x}
                  key={index}
                  setVisibleModal={setVisibleModal}
                  setSelectedTransaction={setSelectedTransaction}
                  updateTMHandler={updateTMHandler}
                  intervalValue={intervalValue}
                />
              ))
              :
              "No data found"
          }
        </div>
      </Card>
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <Details selectedTransaction={selectedTransaction} />
      </Modal>
    </>
  );
};

export default RecentPost;

import React, { useRef, useState } from "react";
import cn from "classnames";
import styles from "./Form.module.sass";
import SimpleReactValidator from "simple-react-validator";
import LoaderScreen from "../../components/LoaderScreen";
import TextInput from "../../components/TextInput";
import Card from "../../components/Card";

const Form = ({ loading, className }) => {
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, setForceUpdate] = useState();
  const [merchantName, setMerchantName] = useState("");
  const [merchantType, setMerchantType] = useState("");
  const [merchantEmail, setMerchantEmail] = useState("");
  const [merchantPhone, setMerchantPhone] = useState("");
  const [merchantAddress1, setMerchantAddress1] = useState("");
  const [merchantAddress2, setMerchantAddress2] = useState("");
  const [merchantCity, setMerchantCity] = useState("");
  const [merchantState, setMerchantState] = useState("");
  const [merchantCountry, setMerchantCountry] = useState("");
  const [merchantAddressProof, setMerchantAddressProof] = useState();

  return (
    <Card
      className={cn(styles.card, className)}
      classTitle={cn("title-blue", styles.title)}
      classCardHead={styles.head}
    >
      <div className={styles.share}>
        {loading && <LoaderScreen />}
        <div className={styles.description}>
          <div className={styles.customField}>
            <TextInput
              className={styles.field}
              label="Merchant Name"
              name="merchantName"
              type="text"
              onChange={(e) => setMerchantName(e.target.value)}
              placeholder="Please enter merchant name"
              required
              onBlur={() => simpleValidator.current.showMessageFor("merchantName")}
            />
            <span className={styles.danger}>
              {simpleValidator.current.message("merchantName", merchantName, "required")}
            </span>
          </div>
          <div className={styles.customField}>
            <TextInput
              className={styles.field}
              label="Merchant Category"
              name="merchantType"
              type="text"
              onChange={(e) => setMerchantType(e.target.value)}
              placeholder="Please enter merchant category"
              required
              onBlur={() => simpleValidator.current.showMessageFor("merchantType")}
            />
            {/* <span className={styles.danger}>
              {simpleValidator.current.message("merchantType", merchantType, "required")}
            </span> */}
          </div>
        </div>
        <div className={styles.description}>
          <div className={styles.customField}>
            <TextInput
              className={styles.field}
              label="Merchant Email"
              name="merchantEmail"
              type="text"
              onChange={(e) => setMerchantEmail(e.target.value)}
              placeholder="Please enter merchant email"
              required
              onBlur={() => simpleValidator.current.showMessageFor("merchantEmail")}
            />
            <span className={styles.danger}>
              {simpleValidator.current.message("merchantEmail", merchantEmail, "required|email")}
            </span>
          </div>
          <div className={styles.customField}>
            <TextInput
              className={styles.field}
              label="Contact Number"
              name="contactNumber"
              type="text"
              onChange={(e) => setMerchantPhone(e.target.value)}
              placeholder="Please enter contact number"
              required
              onBlur={() => simpleValidator.current.showMessageFor("contactNumber")}
            />
            <span className={styles.danger}>
              {simpleValidator.current.message("contactNumber", merchantPhone, "required")}
            </span>
          </div>
        </div>
        <div className={styles.description}>
          <div className={styles.customField}>
            <TextInput
              className={styles.field}
              label="Address 1"
              name="address1"
              type="text"
              onChange={(e) => setMerchantAddress1(e.target.value)}
              placeholder="Please enter address"
              required
              onBlur={() => simpleValidator.current.showMessageFor("address1")}
            />
            {/* <span className={styles.danger}>
              {simpleValidator.current.message("address1", merchantAddress1, "required")}
            </span> */}
          </div>
          <div className={styles.customField}>
            <TextInput
              className={styles.field}
              label="Address 2"
              name="type"
              type="address2"
              onChange={(e) => setMerchantAddress2(e.target.value)}
              placeholder="Please enter address"
              required
              onBlur={() => simpleValidator.current.showMessageFor("address2")}
            />
            {/* <span className={styles.danger}>
              {simpleValidator.current.message("address2", merchantAddress2, "required")}
            </span> */}
          </div>
        </div>
        <div className={styles.description}>
          <div className={styles.customField}>
            <TextInput
              className={styles.field}
              label="City"
              name="city"
              type="text"
              onChange={(e) => setMerchantCity(e.target.value)}
              placeholder="Please enter city"
              required
              onBlur={() => simpleValidator.current.showMessageFor("city")}
            />
            {/* <span className={styles.danger}>
              {simpleValidator.current.message("city", merchantCity, "required")}
            </span> */}
          </div>
          <div className={styles.customField}>
            <TextInput
              className={styles.field}
              label="State"
              name="type"
              type="state"
              onChange={(e) => setMerchantState(e.target.value)}
              placeholder="Please enter address"
              required
              onBlur={() => simpleValidator.current.showMessageFor("state")}
            />
            {/* <span className={styles.danger}>
              {simpleValidator.current.message("state", merchantState, "required")}
            </span> */}
          </div>
        </div>
        <div className={styles.description}>
          <div className={styles.customField}>
            <TextInput
              className={styles.field}
              label="Country"
              name="country"
              type="text"
              onChange={(e) => setMerchantCountry(e.target.value)}
              placeholder="Please enter city"
              required
              onBlur={() => simpleValidator.current.showMessageFor("country")}
            />
            {/* <span className={styles.danger}>
              {simpleValidator.current.message("country", merchantCountry, "required")}
            </span> */}
          </div>
          <div className={styles.customField}>
            <TextInput
              className={styles.field}
              label="Proof"
              name="proof"
              type="file"
              onChange={(e) => setMerchantAddressProof(e.target.files[0])}
              required
              onBlur={() => simpleValidator.current.showMessageFor("proof")}
            />
            {/* <span className={styles.danger}>
              {simpleValidator.current.message("proof", merchantAddressProof, "required")}
            </span> */}
          </div>
        </div>
        <button className={cn("button button-small", styles.button)}
          onClick={(e) => {
            e.stopPropagation();
            if (simpleValidator.current.allValid()) {
            }
            else {
              simpleValidator.current.showMessages();
              setForceUpdate(1);
            }
          }}>
          <span>Continue</span>
        </button>
      </div>

    </Card>


  );
};

export default Form;

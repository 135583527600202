import React from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../../components/Card";
import Row from "./Row";
import Modal from "../../../../components/Modal";
import NewPost from "./NewPost";
import Dropdown from "../../../../components/Dropdown";
const RecentPost = ({
  className,
  userData,
  transactionData,
  visibleModal,
  setVisibleModal,
  setTransactionDetails,
  transactionDetails,
  intervals,
  sorting,
  setSorting,
}) => {
  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Transactions"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <div className={styles.dropdown}>
            <Dropdown
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              value={sorting}
              setValue={setSorting}
              options={intervals}
              small
            />
          </div>
        }
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>From</div>
            <div className={styles.col}>To</div>
            {/* <div className={styles.col}>Debited Amount</div> */}
            <div className={styles.col}> Amount</div>
            <div className={styles.col}>Transaction Id</div>
            <div className={styles.col}>Transaction Types</div>
            <div className={styles.col}>Status</div>
            <div className={styles.col}>Date</div>
          </div>
          {transactionData
            ?.filter((x) => {
              if (sorting.toLowerCase() === "all") {
                return x.type !== "deposit" && x.type !== "withdraw";
              } else {
                return x.type.toLowerCase() === sorting.toLowerCase();
              }
            })
            .map((x, index) => (
              <Row
                item={x}
                key={index}
                setVisibleModal={setVisibleModal}
                setTransactionDetails={setTransactionDetails}
              />
            ))}
        </div>
      </Card>
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <NewPost transactionDetails={transactionDetails} />
      </Modal>
    </>
  );
};

export default RecentPost;

import React from "react";
import styles from "./NewPost.module.sass";
import cn from "classnames";
import { toLocaleStringDateFormat, getDigitsAfterDecimal } from "../../../../components/helper";

const NewPost = ({ transactionDetails }) => {

  return (
    <div className={styles.share}>
      <div className={styles.head}>
        <div className={cn("title-red", styles.title)}>Deposit Details</div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Deposited Amount</div>
        <div className={styles.col}>
          SRD {transactionDetails?.amount !== null || transactionDetails?.amount !== undefined ? getDigitsAfterDecimal(parseFloat(transactionDetails?.amount), 2) : "---"}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Credited Amount</div>
        <div className={styles.col}>
          SRD {transactionDetails?.total_amount !== null || transactionDetails?.total_amount !== undefined ? getDigitsAfterDecimal(parseFloat(transactionDetails?.total_amount), 2) : "---"}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Deducted Fee</div>
        <div className={styles.col}>
          SRD {(transactionDetails?.wallet_transaction_fees !== null || transactionDetails?.wallet_transaction_fees !== undefined) ?
            (transactionDetails?.wallet_transaction_fees?.deposit_fee !== null || transactionDetails?.wallet_transaction_fees?.deposit_fee !== undefined) ?
              getDigitsAfterDecimal(parseFloat(transactionDetails?.wallet_transaction_fees?.deposit_fee), 2) : "---"
            : "---"}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Transaction Id</div>
        <div className={styles.col}>
          {transactionDetails?.txId}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Reference Number</div>
        <div className={styles.col}>
          {transactionDetails?.payment_reference === null || transactionDetails?.payment_reference === undefined ? "---" : transactionDetails?.payment_reference}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Status</div>
        <div className={styles.col}>
          {transactionDetails?.status === parseInt(process.env.REACT_APP_DEPOSIT_STATUS_PENDING) ?
            <div className={cn("status-red-dark", styles.distribution)}>
              Pending
            </div>
            :
            transactionDetails?.status === parseInt(process.env.REACT_APP_DEPOSIT_STATUS_SUCCESS) ?
              <div className={cn("status-green-dark", styles.distribution)}>
                Success
              </div>
              :
              <div className={cn("status-red-dark", styles.distribution)}>
                Declined
              </div>
          }
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Date</div>
        <div className={styles.col}>
          {toLocaleStringDateFormat(transactionDetails?.created_at)}
        </div>
      </div>
    </div>
  );
};

export default NewPost;

import React from "react";
import styles from "./Row.module.sass";
import cn from "classnames";

const Row = ({ item, index }) => {

  return (
    <div className={styles.row}>
      <div className={styles.col}>
        {index + 1}
      </div>
      <div className={styles.col}>
        12345
      </div>
      <div className={styles.col}>
        <div className={cn("status-green-dark", styles.distribution)}>
          Success
        </div>
      </div>
      {/* <div className={styles.col}>
        <div className={cn("status-green-dark", styles.distribution)}>
          Success
        </div>
      </div> */}
      <div className={styles.col}>
        08-Sep-2023
      </div>
    </div>
  );
};

export default Row;

import React from "react";
import cn from "classnames";
import styles from "./ProfileInformation.module.sass";
import Item from "../Item";

const ProfileInformation = ({ className, userData }) => {

  return (
    <Item
      className={cn(styles.card, className)}
      title="KYB Documents"
      classTitle="title-green"
    >
      <div className={styles.fieldset}>
        {
          userData?.address !== null ?
            <>
              <div className={styles.buttonClass}>
                <div className={styles.settings}>
                  <button className={cn("button-small",)} style={{ background: 'green' }} type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      {
                        window.open(userData?.address?.business_proof, '_blank', 'noopener,noreferrer')
                      }
                    }}
                  >
                    <span>View business proof</span>
                  </button>
                </div>
              </div>
              {/* <div className={styles.buttonClass}>
                <div className={styles.settings}>
                  <button className={cn("button-small",)} style={{ background: 'green' }} type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      {
                        window.open(userData?.kyc?.back_image, '_blank', 'noopener,noreferrer')
                      }
                    }}
                  >
                    <span>View back side image</span>
                  </button>
                </div>
              </div> */}
            </>
            :
            <div>No Data Found</div>
        }
      </div>
    </Item>
  );
};

export default ProfileInformation;

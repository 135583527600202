import React from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Row from "./Row";
import CutomerListSkeleton from "../../../components/Skeleton/CutomerListSkeleton";

const RecentPost = ({
  className,
  usersList,
  loading,
  pageCount,
  status,
  setPageCount,
  updateKycStatus,
}) => {
  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Recently Added"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>C Id</div>
            <div className={styles.col}>Customer</div>
            <div className={styles.col}>U Id</div>
            {/* <div className={styles.col}>Last Name</div> */}
            {/* <div className={styles.col}>Email</div> */}
            <div className={styles.col}>KYC Status</div>
            <div className={styles.col}>Bank Status</div>
            <div className={styles.col}>Residence Status</div>
            <div className={styles.col}>Registration Date</div>
            {parseInt(status) ===
              parseInt(process.env.REACT_APP_KYC_STATUS_INPROCESS) ||
              (parseInt(status) ===
                parseInt(process.env.REACT_APP_KYC_STATUS_REVIEW) && (
                <div className={styles.col}>Action</div>
              ))}
          </div>
          {loading ? (
            <CutomerListSkeleton colCount={7} rowCount={12} />
          ) : (
            usersList?.data?.map((x, index) => (
              <Row item={x} key={index} updateKycStatus={updateKycStatus} />
            ))
          )}
        </div>
        {usersList?.data?.length > 0 && (
          <>
            <div className={styles.foot}>
              {usersList?.prev_page_url !== null && (
                <button
                  className={cn("button-stroke button-small", styles.button)}
                  onClick={() => {
                    setPageCount(pageCount - 1);
                  }}
                >
                  <span>Previous</span>
                </button>
              )}
              {usersList?.next_page_url !== null && (
                <button
                  className={cn("button-stroke button-small", styles.button)}
                  onClick={() => {
                    setPageCount(pageCount + 1);
                  }}
                >
                  <span>Next</span>
                </button>
              )}
            </div>
          </>
        )}
      </Card>
    </>
  );
};

export default RecentPost;

import React from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import { toLocaleStringDateFormat, getDigitsAfterDecimal } from "../../../../components/helper";

const Row = ({
  item,
  setUpdateCategoryModal,
  setSelectedCategory
}) => {

  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          <div className={styles.preview}>
            <img src={process.env.REACT_APP_IMAGE_URL + "/" + item.icon} alt="category" />
          </div>
        </div>
        <div className={styles.col}>
          {item?.name}
        </div>
        <div className={styles.col}>
          SRD {getDigitsAfterDecimal(item?.credit_transaction_fee, 2)}
        </div>
        <div className={styles.col}>
          SRD {getDigitsAfterDecimal(item?.debit_transaction_fee, 2)}
        </div>
        <div className={styles.col}>
          {item?.cashback}%
        </div>
        <div className={styles.col}>
          {item?.status === parseInt(process.env.REACT_APP_CATEGORY_ENABLE) ?
            <div className={cn("status-green-dark", styles.distribution)}>
              Enable
            </div> :
            <div className={cn("status-red-dark", styles.distribution)}>
              Disable
            </div>
          }
        </div>
        <div className={styles.col}>
          {toLocaleStringDateFormat(item?.updated_at)}
        </div>
        <div className={styles.col}>
          <button
            className={cn("button-small", styles.button)}
            onClick={() => {
              setUpdateCategoryModal(true);
              setSelectedCategory(item);
            }}
          >
            <span>Update</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default Row;

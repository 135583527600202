import React, { useState } from "react";
import cn from "classnames";
import styles from "./Promote.module.sass";
import RecentPost from "./RecentPost";
import requestHandler from "../../actions/httpClient";
import { addNotification } from "../../components/Notification";

const Promote = ({ status }) => {

  const [loading, setLoading] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [searchField, setSearchField] = useState("");

  const searchCustomerHandler = async () => {
    setLoading(true);
    if (searchField === "") {
      setLoading(false);
      addNotification({
        title: "Alert",
        message: "Please enter email or UId",
        type: "danger",
      });
    }
    else {
      let data = {
        value: searchField,
      };
      try {
        const searchCustomerPayload = await requestHandler("user/user-filter", "post", data, "jwt_token");
        setLoading(false);
        setUsersList(searchCustomerPayload?.data[0]);
      }
      catch (e) {
        setUsersList([]);
        setLoading(false);
        addNotification({
          title: "Error",
          message: e?.data?.error,
          type: "danger",
        });
      };
    }
  };

  return (
    <>
      <div className={styles.section}>
        <RecentPost
          loading={loading}
          searchField={searchField}
          setSearchField={setSearchField}
          usersList={usersList}
          searchCustomerHandler={searchCustomerHandler}
          status={status}
        />
      </div>
    </>
  );
};

export default Promote;

import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Home.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import Overview from "./Overview";
import PopularProducts from "../../components/PopularProducts";
import Comments from "./Comments";
import RefundRequests from "../../components/RefundRequests";
import ProTips from "./ProTips";
import MoreCustomers from "./MoreCustomers";
import ProductViews from "./ProductViews";
import { useDispatch, useSelector } from "react-redux";
import { userDataCreator } from "../../actions/getUserStatus";
import requestHandler from "../../actions/httpClient";
import LoaderScreen from "../../components/LoaderScreen";

const Home = () => {

  const { userStatus } = useSelector((state) => { return state.getUserData });
  const dispatch = useDispatch();
  const [allStatsData, setAllStatsData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllStatsHandler();
    if (userStatus?.length === 0) {
      dispatch(userDataCreator());
    }
  }, []);

  const getAllStatsHandler = async () => {
    setLoading(true);
    try {
      const getAllStatsPayload = await requestHandler("dashboard/all-count", "get", "", "jwt_token");
      setLoading(false);
      setAllStatsData(getAllStatsPayload?.data);
    }
    catch (e) {
      setLoading(false);
    };
  };

  return (
    <>
      {loading && <LoaderScreen />}
      <div className={styles.row}>
        <div className={styles.col}>
          <Overview
            className={styles.card}
            allStatsData={allStatsData}
          />
          <ProductViews className={styles.card} />
          <ProTips className={styles.card} />
          <MoreCustomers />
        </div>
        <div className={styles.col}>
          <PopularProducts className={styles.card} views="4" />
          <Comments className={styles.card} />
          <RefundRequests title="Refund requests" classTitle="title-red" />
        </div>
      </div>
      <TooltipGlodal />
    </>
  );
};

export default Home;

import React from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import { toLocaleStringDateFormat } from "../../../../components/helper";
import { Link } from "react-router-dom";

const Row = ({
  item,
  updateRequestHandler,
  statusText,
  setVisibleModal,
  setUserDetails
}) => {

  return (
    <div className={styles.row} onClick={(e) => {
      e.preventDefault();
      setVisibleModal(true);
      setUserDetails(item);
    }}>
      <div className={styles.col}>
        {item?.user_id}
      </div>
      <Link
        className={styles.col}
        to={{
          pathname: `/user-profile`,
          state: item,
        }}
      >
        {item?.user?.first_name === undefined ||
          item?.user?.first_name === null ||
          item?.user?.last_name === null ||
          item?.user?.last_name === undefined
          ? "---"
          : `${item?.user?.first_name} ${item?.user?.last_name}`}
        <div className={styles.text}>{item?.user?.email}</div>
      </Link>
      <div className={styles.col}>
        {item?.user?.uid}
      </div>
      {
        item?.role === parseInt(process.env.REACT_APP_USER_ROLE) &&
        <div className={styles.col}>
          Customer
        </div>
      }
      <div className={styles.col}>
        {
          item?.status === parseInt(process.env.REACT_APP_DEPOSIT_STATUS_PENDING) ?
            <div className={cn("status-red-dark", styles.distribution)}>
              Pending
            </div>
            :
            <div className={cn("status-green-dark", styles.distribution)}>
              Completed
            </div>
        }
      </div>
      <div className={styles.col}>
        {toLocaleStringDateFormat(item?.created_at)}
      </div>
      {statusText.toLowerCase() === "pending" &&
        <div className={styles.col}>
          <button
            className={cn("button-small", styles.button)}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              updateRequestHandler(item?.user_id, parseInt(process.env.REACT_APP_DEPOSIT_STATUS_SUCCESS));
            }}
          >
            Approve
          </button>
        </div>
      }
    </div>
  );
};

export default Row;

import React from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import {
  toLocaleStringDateFormat,
  getDigitsAfterDecimal,
} from "../../../../components/helper";

const Row = ({ item, setVisibleModal, setTransactionDetails }) => {
  return (
    <div
      className={styles.row}
      onClick={() => {
        setVisibleModal(true);
        setTransactionDetails(item);
      }}
    >
      <div className={styles.col}>
        {item?.from_user?.uid === null || item?.from_user?.uid === undefined
          ? "---"
          : item?.from_user?.uid}
      </div>
      <div className={styles.col}>
        {item?.to_user?.uid === null || item?.to_user?.uid === undefined
          ? "---"
          : item?.to_user?.uid}
      </div>
      {/* <div className={styles.col}>
        SRD {item?.amount}
      </div> */}
      <div className={styles.col}>
        SRD {getDigitsAfterDecimal(item?.total_amount, 2)}
      </div>
      <div className={styles.col}>
        {item?.txId === undefined || item?.txId === null ? "---" : item?.txId}
      </div>
      <div className={styles.col}>
        {item?.type === undefined || item?.type === null
          ? "---"
          : item?.type.charAt(0).toUpperCase() + item?.type.slice(1)}
      </div>

      {/* <div className={styles.col}>
        <div className={cn("status-green-dark", styles.distribution)}>
          Success
        </div>
      </div> */}
      <div className={styles.col}>
        {parseInt(item?.status) ===
        parseInt(process.env.REACT_APP_DEPOSIT_STATUS_PENDING) ? (
          <div className={cn("status-red-dark", styles.distribution)}>
            Pending
          </div>
        ) : parseInt(item?.status) ===
          parseInt(process.env.REACT_APP_DEPOSIT_STATUS_SUCCESS) ? (
          <div className={cn("status-green-dark", styles.distribution)}>
            Approved
          </div>
        ) : parseInt(item?.status) ===
          parseInt(process.env.REACT_APP_DEPOSIT_STATUS_DECLINED) ? (
          <div className={cn("status-red-dark", styles.distribution)}>
            Reject
          </div>
        ) : (
          ""
        )}
      </div>
      {/* <div className={styles.col}>
        {item?.status === parseInt(process.env.REACT_APP_DEPOSIT_STATUS_PENDING) ?
          <div className={cn("status-red-dark", styles.distribution)}>
            Pending
          </div>
          :
          item?.status === parseInt(process.env.REACT_APP_DEPOSIT_STATUS_SUCCESS) ?
            <div className={cn("status-green-dark", styles.distribution)}>
              Success
            </div>
            :
            <div className={cn("status-red-dark", styles.distribution)}>
              Declined
            </div>
        }
      </div> */}
      <div className={styles.col}>
        {toLocaleStringDateFormat(item?.created_at)}
      </div>
    </div>
  );
};

export default Row;

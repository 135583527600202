import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./styles/app.sass";
import Page from "./components/Page";
import Home from "./screens/Home";
import ProductsDashboard from "./screens/ProductsDashboard";
import NewProduct from "./screens/NewProduct";
import Drafts from "./screens/Drafts";
import Released from "./screens/Released";
import Comments from "./screens/Comments";
import Scheduled from "./screens/Scheduled";
import Customers from "./screens/Customers";
import CustomerList from "./screens/CustomerList";
import Promote from "./screens/Promote";
import Notification from "./screens/Notification";
import Settings from "./screens/Settings";
import UpgradeToPro from "./screens/UpgradeToPro";
import MessageCenter from "./screens/MessageCenter";
import ExploreCreators from "./screens/ExploreCreators";
import AffiliateCenter from "./screens/AffiliateCenter";
import SignUp from "./screens/SignUp";
import SignIn from "./screens/SignIn";
import Earning from "./screens/Earning";
import Refunds from "./screens/Refunds";
import Payouts from "./screens/Payouts";
import Statements from "./screens/Statements";
import Shop from "./screens/Shop";
import PageList from "./screens/PageList";
import LogIn from "./screens/LogIn";
import ChangePassword from "./screens/ChangePassword";
import Users from "./screens/Users";
import Merchants from "./screens/Merchants";
import UserProfile from "./screens/UserProfile";
import MerchantProfile from "./screens/MerchantProfile";
import PaidTransactions from "./screens/PaidTransactions";
import ReceivedTransactions from "./screens/ReceivedTransactions";
import DepositTransactions from "./screens/DepositTransactions";
import WithdrawTransactions from "./screens/WithdrawTransactions";
import Categories from "./screens/Categories";
import AddMerchants from "./screens/AddMerchants";
import AddFee from "./screens/AddFee";
import FeeList from "./screens/FeeList";
import SearchUsers from "./screens/SearchUsers";
import ReferralAndBonus from "./screens/ReferralAndBonus";
import SignupBonus from "./screens/SignupBonus";
import TMList from "./screens/TMList";
import Finance from "./screens/Finance";
import TMValues from "./screens/TMValues";
import SubUsers from "./screens/SubUsers";
import SubUserProfile from "./screens/SubUserProfile";
import { ProtectedRoutes } from "../src/components/helper";
import FreezeAccountRequest from "./screens/FreezeAccountRequest";

function App() {
  return (
    <Router>
      <Switch>
        <ProtectedRoutes
          exact
          path="/"
          isAuth={localStorage.getItem("token")}
          component={() => (
            <Page title="Dashboard">
              <Home />
            </Page>
          )}
        />
        <ProtectedRoutes
          exact
          path="/change-password"
          isAuth={localStorage.getItem("token")}
          component={() => (
            <Page title="Change Password">
              <ChangePassword />
            </Page>
          )}
        />
        {/* ------ Customers Route -------- */}

        <ProtectedRoutes
          exact
          path="/customers/complete-customers"
          isAuth={localStorage.getItem("token")}
          component={() => (
            <Page title="Completed Customers">
              <Users status={process.env.REACT_APP_KYC_STATUS_COMPLETED} />
            </Page>
          )}
        />

        <ProtectedRoutes
          exact
          path="/customers/pending-customers"
          isAuth={localStorage.getItem("token")}
          component={() => (
            <Page title="Pending Customers">
              <Users status={process.env.REACT_APP_KYC_STATUS_PENDING} />
            </Page>
          )}
        />

        <ProtectedRoutes
          exact
          path="/customers/in-process-kyc"
          isAuth={localStorage.getItem("token")}
          component={() => (
            <Page title="In-process(KYC) Customers">
              <Users status={process.env.REACT_APP_KYC_STATUS_INPROCESS} />
            </Page>
          )}
        />

        <ProtectedRoutes
          exact
          path="/customers/unverified-customers"
          isAuth={localStorage.getItem("token")}
          component={() => (
            <Page title="Unverified Customers">
              <Users status={process.env.REACT_APP_KYC_STATUS_UNVERIFIED} />
            </Page>
          )}
        />

        <ProtectedRoutes
          exact
          path="/customers/freezed-customers"
          isAuth={localStorage.getItem("token")}
          component={() => (
            <Page title="Freezed Customers">
              <Users status={process.env.REACT_APP_FREEZED_EMAIL_CUSTOMERS} />
            </Page>
          )}
        />
        <ProtectedRoutes
          exact
          path="/customers/in-review-customers"
          isAuth={localStorage.getItem("token")}
          component={() => (
            <Page title="In-Review Customers">
              <Users status={process.env.REACT_APP_KYC_STATUS_REVIEW} />
            </Page>
          )}
        />

        {/* ------ End Customers Route --------*/}

        {/* ------ Fee Route ----------  */}

        <ProtectedRoutes
          exact
          path="/fee"
          isAuth={localStorage.getItem("token")}
          component={() => (
            <Page title="Fee">
              <FeeList />
            </Page>
          )}
        />
        <ProtectedRoutes
          exact
          path="/add-fee"
          isAuth={localStorage.getItem("token")}
          component={() => (
            <Page title="Add Fee">
              <AddFee />
            </Page>
          )}
        />
        {/* ----- End Fee Route----- */}

        {/* User profile route */}

        <ProtectedRoutes
          exact
          path="/user-profile"
          isAuth={localStorage.getItem("token")}
          component={() => (
            <Page title="User Profile">
              <UserProfile />
            </Page>
          )}
        />

        {/* End User profile route */}

        {/* ----- Search User And Merchant ------ */}

        <ProtectedRoutes
          exact
          path="/customers/search-customers"
          isAuth={localStorage.getItem("token")}
          component={() => (
            <Page title="Search Customer">
              <SearchUsers status={parseInt(process.env.REACT_APP_USER_ROLE)} />
            </Page>
          )}
        />

        <ProtectedRoutes
          exact
          path="/business/search-business"
          isAuth={localStorage.getItem("token")}
          component={() => (
            <Page title="Search Business">
              <SearchUsers
                status={parseInt(process.env.REACT_APP_MERCHANT_ROLE)}
              />
            </Page>
          )}
        />

        {/* ----- End Search User And Merchant ------ */}

        {/* ------------ Merchants profile route ---------- */}

        <ProtectedRoutes
          exact
          path="/business/complete-business"
          isAuth={localStorage.getItem("token")}
          component={() => (
            <Page title="Complete Business">
              <Merchants status={process.env.REACT_APP_KYC_STATUS_COMPLETED} />
            </Page>
          )}
        />

        <ProtectedRoutes
          exact
          path="/business/pending-business"
          isAuth={localStorage.getItem("token")}
          component={() => (
            <Page title="Pending Business">
              <Merchants status={process.env.REACT_APP_KYC_STATUS_PENDING} />
            </Page>
          )}
        />

        <ProtectedRoutes
          exact
          path="/business/in-process-kyc"
          isAuth={localStorage.getItem("token")}
          component={() => (
            <Page title="In-process(KYC) Business">
              <Merchants status={process.env.REACT_APP_KYC_STATUS_INPROCESS} />
            </Page>
          )}
        />

        <ProtectedRoutes
          exact
          path="/business/unverified-business"
          isAuth={localStorage.getItem("token")}
          component={() => (
            <Page title="Unverified Business">
              <Merchants status={process.env.REACT_APP_KYC_STATUS_UNVERIFIED} />
            </Page>
          )}
        />

        <ProtectedRoutes
          exact
          path="/business/freezed-business"
          isAuth={localStorage.getItem("token")}
          component={() => (
            <Page title="Freezed Business">
              <Merchants
                status={process.env.REACT_APP_FREEZED_EMAIL_CUSTOMERS}
              />
            </Page>
          )}
        />

        <ProtectedRoutes
          exact
          path="/business/in-review-business"
          isAuth={localStorage.getItem("token")}
          component={() => (
            <Page title="In-Review Business">
              <Merchants status={process.env.REACT_APP_KYC_STATUS_REVIEW} />
            </Page>
          )}
        />

        {/* ------ End of Merchants route ------- */}

        {/* ----- Add Merchant ----- */}

        <ProtectedRoutes
          exact
          path="/add-merchant"
          isAuth={localStorage.getItem("token")}
          component={() => (
            <Page title="Add Merchants">
              <AddMerchants />
            </Page>
          )}
        />

        {/* ----- End of Add Merchant ----- */}

        <ProtectedRoutes
          exact
          path="/sub-users"
          isAuth={localStorage.getItem("token")}
          component={() => (
            <Page title="Sub Users">
              <SubUsers />
            </Page>
          )}
        />
        <ProtectedRoutes
          exact
          path="/sub-users/profile"
          isAuth={localStorage.getItem("token")}
          component={() => (
            <Page title="Sub User Profile">
              <SubUserProfile
                status={process.env.REACT_APP_KYC_STATUS_COMPLETED}
              />
            </Page>
          )}
        />
        <ProtectedRoutes
          exact
          path="/transactions"
          isAuth={localStorage.getItem("token")}
          component={() => (
            <Page title="Transactions">
              <PaidTransactions />
            </Page>
          )}
        />
        {/* <ProtectedRoutes
          exact
          path="/paid-transactions"
          isAuth={localStorage.getItem('token')}
          component={() => (
            <Page title="Paid Transactions">
              <PaidTransactions />
            </Page>
          )}
        /> */}
        {/* <ProtectedRoutes
          exact
          path="/received-transactions"
          isAuth={localStorage.getItem('token')}
          component={() => (
            <Page title="Received Transactions">
              <ReceivedTransactions />
            </Page>
          )}
        /> */}
        <ProtectedRoutes
          exact
          path="/referral-bonus"
          isAuth={localStorage.getItem("token")}
          component={() => (
            <Page title="Referral Bonus">
              <ReferralAndBonus />
            </Page>
          )}
        />
        <ProtectedRoutes
          exact
          path="/signup-bonus"
          isAuth={localStorage.getItem("token")}
          component={() => (
            <Page title="Signup Bonus">
              <SignupBonus />
            </Page>
          )}
        />
        <ProtectedRoutes
          exact
          path="/deposit/pending-deposits"
          isAuth={localStorage.getItem("token")}
          component={() => (
            <Page title="Pending Deposit Transactions">
              <DepositTransactions
                status={process.env.REACT_APP_DEPOSIT_STATUS_PENDING}
              />
            </Page>
          )}
        />
        <ProtectedRoutes
          exact
          path="/deposit/approved-deposits"
          isAuth={localStorage.getItem("token")}
          component={() => (
            <Page title="Approved Deposit Transactions">
              <DepositTransactions
                status={process.env.REACT_APP_DEPOSIT_STATUS_SUCCESS}
              />
            </Page>
          )}
        />
        <ProtectedRoutes
          exact
          path="/withdraw/pending-withdraw"
          isAuth={localStorage.getItem("token")}
          component={() => (
            <Page title="Pending Withdraw Transactions">
              <WithdrawTransactions
                status={process.env.REACT_APP_DEPOSIT_STATUS_PENDING}
              />
            </Page>
          )}
        />
        <ProtectedRoutes
          exact
          path="/withdraw/approved-withdraw"
          isAuth={localStorage.getItem("token")}
          component={() => (
            <Page title="Approved Withdraw Transactions">
              <WithdrawTransactions
                status={process.env.REACT_APP_DEPOSIT_STATUS_SUCCESS}
              />
            </Page>
          )}
        />
        {/* <ProtectedRoutes
          exact
          path="/withdraw-transactions"
          isAuth={localStorage.getItem('token')}
          component={() => (
            <Page title="Withdraw Transactions">
              <WithdrawTransactions />
            </Page>
          )}
        /> */}
        <ProtectedRoutes
          exact
          // path="/merchant-profile/:id"
          path="/merchant-profile"
          isAuth={localStorage.getItem("token")}
          component={() => (
            <Page title="Business Profile">
              <MerchantProfile />
            </Page>
          )}
        />
        <ProtectedRoutes
          exact
          path="/categories"
          isAuth={localStorage.getItem("token")}
          component={() => (
            <Page title="Categories">
              <Categories />
            </Page>
          )}
        />

        {/* Transaction Monitoring Route */}
        <ProtectedRoutes
          exact
          path="/transaction-monitoring"
          isAuth={localStorage.getItem("token")}
          component={() => (
            <Page title="Transaction Monitoring List">
              <TMList />
            </Page>
          )}
        />
        <ProtectedRoutes
          exact
          path="/finance"
          isAuth={localStorage.getItem("token")}
          component={() => (
            <Page title="Finance">
              <Finance />
            </Page>
          )}
        />
        <ProtectedRoutes
          exact
          path="/transaction-monitoring-values"
          isAuth={localStorage.getItem("token")}
          component={() => (
            <Page title="Transaction Monitoring Values">
              <TMValues />
            </Page>
          )}
        />
        <ProtectedRoutes
          exact
          path="/freeze-account-request"
          isAuth={localStorage.getItem("token")}
          component={() => (
            <Page title="Freeze Account Request">
              <FreezeAccountRequest />
            </Page>
          )}
        />
        {/* End Transaction Monitoring Route */}

        <Route
          exact
          path="/products/dashboard"
          render={() => (
            <Page title="Products dashboard">
              <ProductsDashboard />
            </Page>
          )}
        />
        <Route
          exact
          path="/products/add"
          render={() => (
            <Page title="New product">
              <NewProduct />
            </Page>
          )}
        />
        <Route
          exact
          path="/products/drafts"
          render={() => (
            <Page title="Drafts">
              <Drafts />
            </Page>
          )}
        />
        <Route
          exact
          path="/products/released"
          render={() => (
            <Page title="Released">
              <Released />
            </Page>
          )}
        />
        <Route
          exact
          path="/products/comments"
          render={() => (
            <Page title="Comments">
              <Comments />
            </Page>
          )}
        />
        <Route
          exact
          path="/products/scheduled"
          render={() => (
            <Page title="Scheduled">
              <Scheduled />
            </Page>
          )}
        />
        <Route
          exact
          path="/customers/overview"
          render={() => (
            <Page title="Customers">
              <Customers />
            </Page>
          )}
        />
        <Route
          exact
          path="/customers/customer-list"
          render={() => (
            <Page title="Customer list">
              <CustomerList />
            </Page>
          )}
        />
        <Route
          exact
          path="/shop"
          render={() => (
            <Page wide>
              <Shop />
            </Page>
          )}
        />
        <Route
          exact
          path="/income/earning"
          render={() => (
            <Page title="Earning">
              <Earning />
            </Page>
          )}
        />
        <Route
          exact
          path="/income/refunds"
          render={() => (
            <Page title="Refunds">
              <Refunds />
            </Page>
          )}
        />
        <Route
          exact
          path="/income/payouts"
          render={() => (
            <Page title="Payouts">
              <Payouts />
            </Page>
          )}
        />
        <Route
          exact
          path="/income/statements"
          render={() => (
            <Page title="Statements">
              <Statements />
            </Page>
          )}
        />
        <Route
          exact
          path="/promote"
          render={() => (
            <Page title="Promote">
              <Promote />
            </Page>
          )}
        />
        <Route
          exact
          path="/notification"
          render={() => (
            <Page title="Notification">
              <Notification />
            </Page>
          )}
        />
        <Route
          exact
          path="/settings"
          render={() => (
            <Page title="Settings">
              <Settings />
            </Page>
          )}
        />
        <Route
          exact
          path="/upgrade-to-pro"
          render={() => (
            <Page title="Upgrade to Pro">
              <UpgradeToPro />
            </Page>
          )}
        />
        <Route
          exact
          path="/message-center"
          render={() => (
            <Page title="Message center">
              <MessageCenter />
            </Page>
          )}
        />
        <Route
          exact
          path="/explore-creators"
          render={() => (
            <Page title="Explore creators">
              <ExploreCreators />
            </Page>
          )}
        />
        <Route
          exact
          path="/affiliate-center"
          render={() => (
            <Page title="Affiliate center">
              <AffiliateCenter />
            </Page>
          )}
        />

        <Route exact path="/sign-in" render={() => <LogIn />} />
        {/* <Route exact path="/sign-in" render={() => <SignUp />} /> */}
        {/* <Route exact path="/sign-in" render={() => <SignIn />} /> */}
        <Route exact path="/pagelist" component={PageList} />
      </Switch>
    </Router>
  );
}

export default App;

import React from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../../components/Card";
import Row from "./Row";

const RecentPost = ({
  className,
  userData,
}) => {

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Sub Users"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>Id</div>
            <div className={styles.col}>Customer</div>
            <div className={styles.col}>Balance</div>
            <div className={styles.col}>U Id</div>
            <div className={styles.col}>Status</div>
            <div className={styles.col}>Date</div>
          </div>
          {
            userData?.subuser?.length > 0 ?
              userData?.subuser?.map((x, index) => (
                <Row
                  item={x}
                  key={index}
                />
              )) :
              "No data found"}
        </div>
      </Card>
    </>
  );
};

export default RecentPost;

import React from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import { toLocaleStringDateFormat, getDigitsAfterDecimal } from "../../../../components/helper";

const Row = ({
  item,
  setVisibleModal,
  setSelectedTransaction,
  updateTMHandler,
  intervalValue
}) => {

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  function hoursToDays(hours) {
    return hours / 24;
  };

  return (
    <div className={styles.row} onClick={() => {
      setVisibleModal(true);
      setSelectedTransaction(item);
    }}>
      <div className={styles.col}>
        {item?.user_id}
      </div>
      <div className={styles.col}>
        {item?.module === "cashrecharge" ? "Cash Recharge" : capitalizeFirstLetter(item?.module)}
      </div>
      <div className={styles.col}>
        SRD {getDigitsAfterDecimal(item?.total_amount, 2)}
      </div>
      <div className={styles.col}>
        {item?.duration === "0" ? "Single" : `${hoursToDays(item?.duration)} ${item?.duration === 24 ? "Day" : "Days"}`}
      </div>
      {intervalValue.toLowerCase() === "completed" &&
        <div className={styles.col}>
          {item?.checked_by}
        </div>
      }
      <div className={styles.col}>
        {toLocaleStringDateFormat(item?.created_at)}
      </div>
      {intervalValue.toLowerCase() === "pending" &&
        <div className={styles.col}>
          <div>
            <button className={cn("button-small", styles.acceptButton)} type="button"
              onClick={(e) => {
                e.stopPropagation();
                updateTMHandler(item?.id);
              }}
            >
              <span>Mark as Checked</span>
            </button>
          </div>
        </div>
      }
    </div>
  );
};

export default Row;

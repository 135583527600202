import React from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../../components/Card";
import Row from "./Row";
import Modal from "../../../../components/Modal";
import NewPost from "./NewPost";

const RecentPost = ({
  className,
  userData,
  transactionData,
  visibleModal,
  setVisibleModal,
  setTransactionDetails,
  transactionDetails,
}) => {
  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Transactions"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>From</div>
            <div className={styles.col}>To</div>
            {/* <div className={styles.col}>Debited Amount</div> */}
            <div className={styles.col}>Credited Amount</div>
            <div className={styles.col}>Transaction Id</div>
            <div className={styles.col}>Transaction Type</div>
            <div className={styles.col}>Status</div>
            <div className={styles.col}>Date</div>
          </div>
          {transactionData
            ?.filter((y) => {
              return y.type === "topup";
            })
            ?.map((x, index) => (
              <Row
                item={x}
                key={index}
                setVisibleModal={setVisibleModal}
                setTransactionDetails={setTransactionDetails}
              />
            ))}
        </div>
      </Card>
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <NewPost transactionDetails={transactionDetails} />
      </Modal>
    </>
  );
};

export default RecentPost;

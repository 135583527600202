import React from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import { toLocaleStringDateFormat, getDigitsAfterDecimal } from "../../../../components/helper";

const Row = ({
  item,
  setUpdateCategoryModal,
  setselectedReferral,
  index
}) => {

  function capitalizeFirstChar(str) {
    return str.substring(0, 1).toUpperCase() + str.substring(1);
  };

  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          {index + 1}
        </div>
        <div className={styles.col}>
          {item?.name}
        </div>
        <div className={styles.col}>
          SRD {getDigitsAfterDecimal(item?.amount, 2)}
        </div>
        <div className={styles.col}>
          {capitalizeFirstChar(item?.type)}
        </div>
        <div className={styles.col}>
          {item?.status === parseInt(process.env.REACT_APP_REFERRAL_STATUS_DISABLE) ?
            <div className={cn("status-red-dark", styles.distribution)}>
              Disabled
            </div> :
            <div className={cn("status-green-dark", styles.distribution)}>
              Enable
            </div>
          }
        </div>
        <div className={styles.col}>
          {toLocaleStringDateFormat(item?.updated_at)}
        </div>
        <div className={styles.col}>
          <button
            className={cn("button-small", styles.button)}
            onClick={() => {
              setUpdateCategoryModal(true);
              setselectedReferral(item);
            }}
          >
            <span>Update</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default Row;

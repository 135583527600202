import React from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../../components/Card";
import Row from "./Row";
import Modal from "../../../../components/Modal";
import Details from "./Details";
import Icon from "../../../../components/Icon";
import Onfido from "../../../../components/Onfido";

const RecentPost = ({
  className,
  userData,
  addDirectorHandler,
  setVisibleModal,
  visibleModal,
  directorDetails,
  onfidoWindowModal,
  setOnfidoWindowModal,
  token,
  workFlowRunId,
  applicantId,
  getUserDetailsHandler,
  getDirectorDetails,
  updateOnfidoHandler
}) => {

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Director Information"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <div className={styles.control}>
            <button className={cn("button", styles.button)} onClick={() => setVisibleModal(true)}>
              <Icon name="add" size="20" />
              <span>Add Director</span>
            </button>
          </div>
        }
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>First Name</div>
            <div className={styles.col}>Last Name</div>
            <div className={styles.col}>Email</div>
            <div className={styles.col}>Phone Number</div>
            <div className={styles.col}>KYB Status</div>
            <div className={styles.col}>Action</div>
          </div>
          {userData?.director?.map((x, index) => (
            <Row
              item={x}
              key={index}
              directorDetails={directorDetails}
              getDirectorDetails={getDirectorDetails}
              updateOnfidoHandler={updateOnfidoHandler}
              setOnfidoWindowModal={setOnfidoWindowModal}
            />
          ))}
        </div>
        <Modal
          visible={visibleModal}
          onClose={() => setVisibleModal(false)}
        >
          <Details
            addDirectorHandler={addDirectorHandler}
            token={token}
            workFlowRunId={workFlowRunId}
            applicantId={applicantId}
            getUserDetailsHandler={getUserDetailsHandler}
          />
        </Modal>
        <Modal
          visible={onfidoWindowModal}
          onClose={() => setOnfidoWindowModal(false)}
        >
          <Onfido
            applicantId={applicantId}
            addDirectorHandler={addDirectorHandler}
            token={token}
            workFlowRunId={workFlowRunId}
          />
        </Modal>
      </Card>
    </>
  );
};

export default RecentPost;

import React from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Row from "./Row";
import CustomerListSkeleton from "../../../components/Skeleton/CutomerListSkeleton";

const RecentPost = ({ className, loading, transactionData, status, updateTransactionHandler }) => {

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Recent transactions"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>Amount</div>
            <div className={styles.col}>Customer Id</div>
            <div className={styles.col}>Reference Number</div>
            <div className={styles.col}>Status</div>
            <div className={styles.col}>Date</div>
            {parseInt(status) === parseInt(process.env.REACT_APP_DEPOSIT_STATUS_PENDING) && <div className={styles.col}>Action</div>}
          </div>
          {loading ?
            parseInt(status) === parseInt(process.env.REACT_APP_DEPOSIT_STATUS_PENDING) ?
              <CustomerListSkeleton colCount={6} rowCount={12} />
              :
              <CustomerListSkeleton colCount={5} rowCount={12} />
            :
            transactionData?.length !== 0 ?
              transactionData?.map((x, index) => (
                <Row
                  item={x}
                  key={index}
                  status={status}
                  updateTransactionHandler={updateTransactionHandler}
                />
              ))
              :
              "No data found"
          }
        </div>
      </Card>
    </>
  );
};

export default RecentPost;

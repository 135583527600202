import React from "react";
import styles from "./NewPost.module.sass";
import cn from "classnames";
import { toLocaleStringDateFormat } from "../../../../components/helper";

const NewPost = ({ userDetails }) => {

  return (
    <div className={styles.share}>
      <div className={styles.head}>
        <div className={cn("title-red", styles.title)}>Request Details</div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>User ID</div>
        <div className={styles.col}>
          {userDetails?.user_id === null || userDetails?.user_id === undefined
            ? "---"
            : userDetails?.user_id}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Reason</div>
        <div className={styles.col}>
          {userDetails?.reason}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>UId</div>
        <div className={styles.col}>
          {userDetails?.user.uid === null || userDetails?.user.uid === undefined
            ? "---"
            : userDetails?.user.uid}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Name</div>
        <div className={styles.col}>
          {userDetails?.user.full_name === null || userDetails?.user.full_name === undefined
            ? "---"
            : userDetails?.user.full_name}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Status</div>
        <div className={styles.col}>
          {
            userDetails?.status === parseInt(process.env.REACT_APP_DEPOSIT_STATUS_PENDING) ?
              <div className={cn("status-red-dark", styles.distribution)}>
                Pending
              </div>
              :
              <div className={cn("status-green-dark", styles.distribution)}>
                Completed
              </div>
          }
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Date</div>
        <div className={styles.col}>
          {toLocaleStringDateFormat(userDetails?.created_at)}
        </div>
      </div>
    </div>
  );
};

export default NewPost;

import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Promote.module.sass";
import RecentPost from "./RecentPost";
import requestHandler from "../../actions/httpClient";
import axios from "axios";
import { addNotification } from "../../components/Notification";

const Promote = ({ status }) => {
  const [loading, setLoading] = useState(true);
  const [usersList, setUsersList] = useState([]);
  const [pageCount, setPageCount] = useState(1);

  const getUserListHandler = async () => {
    setLoading(true);
    let data = {
      role: parseInt(process.env.REACT_APP_USER_ROLE),
      kyc_verification: parseInt(status),
    };
    try {
      const getUserPayload = await requestHandler(`user/index?page=${pageCount}`, "post", data, "jwt_token");
      setLoading(false);
      setUsersList(getUserPayload?.data?.data);
    } catch (e) {
      setLoading(false);
    }
  };

  const updateKycStatus = async (id, status) => {
    let formData = {
      user_id: id,
      status: status,
    };
    try {
      setLoading(true);
      const getUserPayload = await requestHandler("user/update-kyc-status-user", "post", formData, "jwr_token");
      setLoading(false);
      if (getUserPayload && getUserPayload.status === 200) {
        addNotification({
          title: "Success",
          message: getUserPayload?.data?.message,
          type: "success",
        });
        getUserListHandler();
      }
    } catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: e?.data?.error,
        type: "danger",
      });
    }
  };

  // const fetchData = async () => {
  //   setLoading(true);
  //   const bearerToken = localStorage.getItem("token");
  //   try {
  //     const response = await axios.get(process.env.REACT_APP_API_URL + "/user/index", {
  //       headers: {
  //         Authorization: `Bearer ${bearerToken}`,
  //       },
  //     });
  //     setLoading(false);
  //     setUsersList(response?.data?.data);
  //   } catch (error) {
  //     setLoading(false);
  //   }
  // };

  useEffect(() => {
    getUserListHandler();
  }, [pageCount]);

  return (
    <>
      <div className={styles.section}>
        <RecentPost
          usersList={usersList}
          loading={loading}
          status={status}
          setPageCount={setPageCount}
          pageCount={pageCount}
          updateKycStatus={updateKycStatus}
        />
      </div>
    </>
  );
};

export default Promote;

import React from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import { toLocaleStringDateFormat, getDigitsAfterDecimal } from "../../../../components/helper";

const Row = ({ item, status, updateTransactionHandler }) => {

  return (
    <div className={styles.row}>
      <div className={styles.col}>
        SRD {getDigitsAfterDecimal(item?.amount, 2)}
      </div>
      <div className={styles.col}>
        {item?.user_id}
      </div>
      <div className={styles.col}>
        <div className={styles.cell}>
          <div className={styles.box}>
            <div className={styles.number}>
              {item?.transaction === null || item?.transaction === undefined ? "---" : item?.transaction.payment_reference}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.col}>
        {parseInt(item?.status) === parseInt(process.env.REACT_APP_DEPOSIT_STATUS_PENDING) ?
          <div className={cn("status-red-dark", styles.distribution)}>
            Pending
          </div>
          :
          parseInt(item?.status) === parseInt(process.env.REACT_APP_DEPOSIT_STATUS_SUCCESS) ?
            <div className={cn("status-green-dark", styles.distribution)}>
              Success
            </div>
            :
            <div className={cn("status-red-dark", styles.distribution)}>
              Declined
            </div>
        }
      </div>
      <div className={styles.col}>
        {toLocaleStringDateFormat(item?.created_at)}
      </div>
      {parseInt(status) === parseInt(process.env.REACT_APP_DEPOSIT_STATUS_PENDING) &&
        <div className={styles.col}>
          <div className={styles.row}>
            <div>
              <button className={cn("button", styles.acceptButton)} type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  updateTransactionHandler(item?.id, parseInt(process.env.REACT_APP_DEPOSIT_STATUS_SUCCESS));
                }}
              >
                <span>Approve</span>
              </button>
              <button className={cn("button", styles.rejectButton)} type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  updateTransactionHandler(item?.id, parseInt(process.env.REACT_APP_DEPOSIT_STATUS_DECLINED));
                }}
              >
                <span>Decline</span>
              </button>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default Row;

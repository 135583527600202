import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Settings.module.sass";
import ProfileInformation from "./ProfileInformation";
import KycDocuments from "./KycDocuments";
import BankInformation from "./BankInformation";
import ReferralList from "./ReferralList";
import ResidenceInformation from "./ResidenceInformation";
import SubUsers from "./SubUsers";
import CashbackList from "./CashbackList";
import PaidTransactions from "./PaidTransactions";
import DepositTransactions from "./DepositTransactions";
import WithdrawTransactions from "./WithdrawTransactions";
import WalletDetails from "./WalletDetails";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import requestHandler from "../../actions/httpClient";
import LoaderScreen from "../../components/LoaderScreen";
import { addNotification } from "../../components/Notification";

const Settings = ({}) => {
  const navigation = [
    {
      title: "Basic Information",
    },
    {
      title: "Bank Account Details",
    },
    {
      title: "Residence Details",
    },
    {
      title: "Wallet Details",
    },
    {
      title: "Transactions",
    },
    // {
    //   title: "Received Transactions",
    // },
    {
      title: "Fiat Deposit ",
    },
    {
      title: "Fiat Withdraw ",
    },
    {
      title: "Kyc Documents",
    },
    {
      title: "Referrals",
    },
    {
      title: "Cashbacks",
    },
    {
      title: "Sub Users",
    },
  ];

  const options = [];
  navigation.map((x) => options.push(x.title));
  const [activeIndex, setActiveIndex] = useState(0);
  const { state } = useLocation();
  const [userData, setUserData] = useState([]);
  const [loading, setloading] = useState(false);
  const [pageRefreshFlag, setPageRefreshFlag] = useState(false);

  const handleClick = (x, index) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    getUserDetailsHandler();
  }, [pageRefreshFlag]);

  const getUserDetailsHandler = async () => {
    setloading(true);
    let data = {
      user_id: state?.id,
    };
    try {
      const getUserDetailsPayload = await requestHandler(
        "user/user-detail",
        "post",
        data,
        "jwt_token"
      );
      setloading(false);

      setUserData(getUserDetailsPayload?.data?.data);
    } catch (e) {
      setloading(false);
    }
  };

  const updateUserHandler = async (userData) => {
    setloading(true);
    let data = {
      id: state?.id,
      first_name: userData?.first_name,
      last_name: userData?.last_name,
      kyc_verification: userData?.kyc_verification,
      status: userData?.status,
    };
    try {
      const updateUserPayload = await requestHandler(
        "user/update-profile",
        "post",
        data,
        "jwt_token"
      );
      setPageRefreshFlag(!pageRefreshFlag);
      setloading(false);
      if (updateUserPayload && updateUserPayload.status === 200) {
        addNotification({
          title: "Success",
          message: updateUserPayload?.data?.message,
          type: "success",
        });
      }
    } catch (e) {
      setloading(false);
      setPageRefreshFlag(!pageRefreshFlag);
      addNotification({
        title: "Error",
        message: e?.data?.message,
        type: "danger",
      });
    }
  };

  const updateBankDetailsHandler = async (userId, id, status) => {
    setloading(true);
    let data = {
      bank_id: id,
      user_id: userId,
      status: status,
    };
    try {
      const updateBankDetailsPayload = await requestHandler(
        "bankDetail/update-status",
        "post",
        data,
        "jwt_token"
      );
      setloading(false);
      if (updateBankDetailsPayload && updateBankDetailsPayload.status === 200) {
        addNotification({
          title: "Success",
          message: updateBankDetailsPayload?.data?.message,
          type: "success",
        });
        getUserDetailsHandler();
      }
    } catch (e) {
      setloading(false);
      addNotification({
        title: "Error",
        message: e?.data?.message,
        type: "danger",
      });
    }
  };

  const updateResidenceDetails = async (
    id,
    address1,
    address2,
    city,
    country,
    state,
    zipCode,
    image
  ) => {
    setloading(true);
    let data = {
      user_id: id,
      address1: address1,
      address2: address2,
      state: state,
      city: city,
      country: country,
      pincode: zipCode,
      image: image,
    };
    try {
      const updateResidencePayload = await requestHandler(
        "address/update-address",
        "post",
        data,
        "jwt_token",
        "file"
      );
      setloading(false);
      if (updateResidencePayload && updateResidencePayload.status === 200) {
        addNotification({
          title: "Success",
          message: updateResidencePayload?.data?.message,
          type: "success",
        });
        getUserDetailsHandler();
      }
    } catch (e) {
      setloading(false);
      addNotification({
        title: "Error",
        message: e?.data?.error,
        type: "danger",
      });
    }
  };

  return (
    <>
      <div className={styles.settings}>
        <div className={styles.menu}>
          {navigation.map((x, index) => (
            <button
              className={cn(styles.button, {
                [styles.active]: activeIndex === index,
              })}
              key={index}
              onClick={() => handleClick(x, index)}
            >
              {x.title}
            </button>
          ))}
        </div>
        <div className={styles.wrapper}>
          {loading && <LoaderScreen />}
          <div className={styles.list}>
            {activeIndex === 0 && (
              <ProfileInformation
                userData={userData}
                setUserData={setUserData}
                updateUserHandler={updateUserHandler}
              />
            )}
            {activeIndex === 1 && (
              <BankInformation
                userData={userData}
                updateBankDetailsHandler={updateBankDetailsHandler}
              />
            )}
            {activeIndex === 2 && (
              <ResidenceInformation
                userData={userData}
                updateResidenceDetails={updateResidenceDetails}
              />
            )}
            {activeIndex === 3 && <WalletDetails userData={userData} />}
            {activeIndex === 4 && (
              <PaidTransactions
                userData={userData}
                state={state}
                loading={loading}
                setloading={setloading}
              />
            )}
            {activeIndex === 5 && (
              <DepositTransactions
                state={state}
                userData={userData}
                loading={loading}
                setloading={setloading}
              />
            )}
            {activeIndex === 6 && (
              <WithdrawTransactions
                state={state}
                userData={userData}
                loading={loading}
                setloading={setloading}
              />
            )}
            {activeIndex === 7 && <KycDocuments userData={userData} />}
            {activeIndex === 8 && <ReferralList userData={userData} />}
            {activeIndex === 9 && <CashbackList userData={userData} />}
            {activeIndex === 10 && <SubUsers userData={userData} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;

import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Promote.module.sass";
import RecentPost from "./RecentPost";
import requestHandler from "../../../actions/httpClient";

const intervals = ["All", "Debited", "Credited", "Cashrecharge", "Topup"];

const Promote = ({ userData, state, setloading }) => {
  const [transactionData, setTransactionData] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [transactionDetails, setTransactionDetails] = useState([]);
  const [sorting, setSorting] = useState(intervals[0]);

  useEffect(() => {
    getTransactionList();
  }, []);

  const getTransactionList = async () => {
    setloading(true);
    let data = {
      user_id: state?.id,
    };
    try {
      const getTransactionListPayload = await requestHandler("user/user-transaction", "post", data, "jwt_token");
      setloading(false);
      let dataFilter = getTransactionListPayload?.data?.data;
      let filterCreditdebit = dataFilter?.map((x, index) => {
        if (x?.type === "debited" || x?.type === "credited") {
          if (x?.from_user?.id === state?.id) {
            return { ...x, type: "debited" };
          } else {
            return { ...x, type: "credited" };
          }
        } else {
          return { ...x };
        }
      });
      let newArr = [...filterCreditdebit];
      let filteredArray = newArr.map((x, index) => {
        if (x?.type === "debited" || x?.type === "credited") {
          if (
            index < newArr.length - 1 &&
            newArr[index].txId === newArr[index + 1].txId
          ) {
            if (newArr[index].type == "debited") {
              return newArr[index + 1];
            } else {
              return newArr[index];
            }
          } else {
          }
        } else {
          return x;
        }
      });
      let newArray = filteredArray.filter((item) => item !== undefined);
      setTransactionData(newArray);
    } catch (e) {
      setloading(false);
    }
  };

  return (
    <div className={styles.section}>
      <RecentPost
        userData={userData}
        transactionData={transactionData}
        visibleModal={visibleModal}
        setVisibleModal={setVisibleModal}
        transactionDetails={transactionDetails}
        setTransactionDetails={setTransactionDetails}
        sorting={sorting}
        setSorting={setSorting}
        intervals={intervals}
      />
    </div>
  );
};

export default Promote;

import React from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import {
  toLocaleStringDateFormat,
  getDigitsAfterDecimal,
} from "../../../../../components/helper";

const Row = ({ item, setVisibleModal, setTransactionDetails }) => {
  return (
    <div
      className={styles.row}
      onClick={() => {
        setVisibleModal(true);
        setTransactionDetails(item);
      }}
    >
      <div className={styles.col}>
        {item?.from_user?.uid !== null || item?.from_user?.uid !== undefined
          ? item?.from_user?.uid
          : "---"}
      </div>
      <div className={styles.col}>
        {item?.to_user?.uid !== null || item?.to_user?.uid !== undefined
          ? item?.to_user?.uid
          : "---"}
      </div>
      {/* <div className={styles.col}>
        {item?.amount === undefined || item?.amount === null
          ? "---"
          : parseFloat(item?.amount).toFixed(2)}
      </div> */}

      <div className={styles.col}>
        SRD {getDigitsAfterDecimal(item?.total_amount, 2)}
      </div>
      <div className={styles.col}>
        {item?.txId === undefined || item?.txId === null ? "---" : item?.txId}
      </div>
      <div className={styles.col}>
        {item?.type === undefined || item?.type === null
          ? "---"
          : item?.type.charAt(0).toUpperCase() + item?.type.slice(1)}
      </div>
      <div className={styles.col}>
        <div className={cn("status-green-dark", styles.distribution)}>
          Success
        </div>
      </div>
      <div className={styles.col}>
        {toLocaleStringDateFormat(item?.created_at)}
      </div>
    </div>
  );
};

export default Row;

import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Promote.module.sass";
import RecentPost from "./RecentPost";
import requestHandler from "../../actions/httpClient";
import { addNotification } from "../../components/Notification";

const Promote = ({ status }) => {
  const [loading, setLoading] = useState(false);
  const [transactionData, setTransactionData] = useState([]);
  const [searchField, setSearchField] = useState("");
  const [visibleModal, setVisibleModal] = useState(false);
  const [transactionDetails, setTransactionDetails] = useState([]);

  useEffect(() => {
    getTransactionsData();
  }, [status]);

  const getTransactionsData = async () => {
    setLoading(true);
    let data = {
      type: "deposit",
      status: parseInt(status),
    };
    try {
      const getTransactionDataPayload = await requestHandler(
        "transaction/list",
        "post",
        data,
        "jwt_token"
      );
      setLoading(false);
      setTransactionData(getTransactionDataPayload?.data?.data);
    } catch (e) {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const updateTransactionHandler = async (id, status, user_id, amount, fee) => {
    setLoading(true);
    let data = {
      id: id,
      status: status,
      user_id: user_id,
      total_amount: amount,
      deposit_fee: fee,
    };
    try {
      const updateTransactionPayload = await requestHandler(
        "wallet/update-balance-status",
        "post",
        data,
        "jwt_token"
      );
      setLoading(false);
      if (updateTransactionPayload.status === 200) {
        addNotification({
          title: "Success",
          message: updateTransactionPayload?.data?.message,
          type: "success",
        });
        getTransactionsData();
      }
    } catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: e?.data?.message || "something went wrong.",
        type: "danger",
      });
    }
  };

  return (
    <>
      <div className={styles.section}>
        <RecentPost
          loading={loading}
          visibleModal={visibleModal}
          setVisibleModal={setVisibleModal}
          handleSubmit={handleSubmit}
          searchField={searchField}
          setSearchField={setSearchField}
          transactionData={transactionData}
          transactionDetails={transactionDetails}
          setTransactionDetails={setTransactionDetails}
          status={status}
          updateTransactionHandler={updateTransactionHandler}
        />
      </div>
    </>
  );
};

export default Promote;

import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Promote.module.sass";
import RecentPost from "./RecentPost";
import requestHandler from "../../actions/httpClient";
import { addNotification } from "../../components/Notification";

const Promote = () => {

  const [loading, setLoading] = useState(false);
  const [referralData, setReferralData] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [updateCategoryModal, setUpdateCategoryModal] = useState(false);

  useEffect(() => {
    getReferralDatHandler();
  }, []);

  const getReferralDatHandler = async () => {
    setLoading(true);
    try {
      const getReferralDataPayload = await requestHandler("referral-bonus-list", "get", "", "jwt_token");
      setLoading(false);
      setReferralData(getReferralDataPayload?.data?.data);
    }
    catch (e) {
      setLoading(false);
    }
  };

  const addReferralHandler = async (name, amount, status, type) => {
    setLoading(true);
    let data = {
      name: name,
      amount: amount,
      status: status,
      type: "referral",
    };
    try {
      const addReferralPayload = await requestHandler("referral-bonus", "post", data, "jwt_token");
      setLoading(false);
      if (addReferralPayload && addReferralPayload.status === 200) {
        addNotification({
          title: "Success",
          message: addReferralPayload?.data?.message,
          type: "success"
        });
      }
      setVisibleModal(false);
      getReferralDatHandler();
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Alert",
        message: e?.data?.error,
        type: "danger"
      });
    };
  };

  const updateReferralHandler = async (id, status, referralName, referralAmount) => {
    setLoading(true);
    let data = {
      id: id,
      status: status,
      name: referralName,
      amount: referralAmount,
      type: "referral",
    };
    try {
      const updateCategoryPayload = await requestHandler("update-bonus-status", "post", data, "jwt_token");
      setLoading(false);
      if (updateCategoryPayload && updateCategoryPayload?.status === 200) {
        addNotification({
          title: "Success",
          message: updateCategoryPayload?.data?.message,
          type: "success"
        });
        setUpdateCategoryModal(false);
        getReferralDatHandler();
      }
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: e?.data?.error,
        type: "danger"
      });
    };
  };

  return (
    <>
      <div className={styles.section}>
        <RecentPost
          referralData={referralData}
          loading={loading}
          addReferralHandler={addReferralHandler}
          visibleModal={visibleModal}
          setVisibleModal={setVisibleModal}
          updateReferralHandler={updateReferralHandler}
          updateCategoryModal={updateCategoryModal}
          setUpdateCategoryModal={setUpdateCategoryModal}
        />
      </div>
    </>
  );
};

export default Promote;

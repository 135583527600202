import React from "react";
import cn from "classnames";
import styles from "./Share.module.sass";
import { toLocaleStringDateFormat, getDigitsAfterDecimal } from "../../../../../components/helper";

const Share = ({ selectedTransaction }) => {

  return (
    <>
      <div className={styles.details}>
        <div className={cn("title-purple", styles.title)}>Transaction Details</div>
        <div className={styles.wrapper}>
          <div className={styles.row}>
            <div className={styles.col}>Amount</div>
            <div className={styles.col}>Transaction Id</div>
            <div className={styles.col}>Reference Number</div>
            {/* <div className={styles.col}>Date</div> */}
          </div>
          {
            selectedTransaction?.data?.map((x, index) => {
              return (
                <div className={styles.row} key={index}>
                  <div className={styles.col}>
                    SRD {getDigitsAfterDecimal(x?.amount, 2)}
                  </div>
                  <div className={styles.col}>
                    {x?.order_id}
                  </div>
                  <div className={styles.col}>
                    {x?.reference_number}
                  </div>
                  {/* <div className={styles.col}>
                    {toLocaleStringDateFormat(x?.created_at)}
                  </div> */}
                </div>
              )
            })
          }
        </div>
      </div>
    </>
  );
};

export default Share;
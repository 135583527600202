import React, { useState } from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Modal from "../../../components/Modal";
import Row from "./Row";
import Form from "./Form";
import UpdateForm from "./UpdateForm";
import LoaderScreen from "../../../components/LoaderScreen";

const RecentPost = ({
  className,
  categoryData,
  loading,
  setPageCount,
  pageCount,
  addCategoryHandler,
  visibleModal,
  setVisibleModal,
  updateCategoryHandler,
  setUpdateCategoryModal,
  updateCategoryModal }) => {

  const [selectedCategory, setSelectedCategory] = useState([]);

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Recently Added"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <button
              className={cn("button-small", styles.button)}
              onClick={() => setVisibleModal(true)}
            >
              Add New Category
            </button>
          </>
        }
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>Image</div>
            <div className={styles.col}>Name</div>
            <div className={styles.col}>Fee(Paid by Business)</div>
            <div className={styles.col}>Fee(Paid by Consumer)</div>
            <div className={styles.col}>Cashback Percentage</div>
            <div className={styles.col}>Status</div>
            <div className={styles.col}>Date</div>
            <div className={styles.col}>Action</div>
          </div>
          {
            loading ? <LoaderScreen /> :
              categoryData?.length !== 0 ?
                categoryData?.map((x, index) => (
                  <Row
                    item={x}
                    key={index}
                    setUpdateCategoryModal={setUpdateCategoryModal}
                    setSelectedCategory={setSelectedCategory}
                  />
                ))
                :
                "No data found"
          }
        </div>
        {
          categoryData?.data?.length > 0 &&
          <>
            <div className={styles.foot}>
              {
                categoryData?.prev_page_url !== null &&
                <button className={cn("button-stroke button-small", styles.button)} onClick={() => { setPageCount(pageCount - 1) }}>
                  <span>Previous</span>
                </button>
              }
              {
                categoryData?.next_page_url !== null &&
                <button className={cn("button-stroke button-small", styles.button)} onClick={() => { setPageCount(pageCount + 1) }}>
                  <span>Next</span>
                </button>
              }
            </div>
          </>
        }
      </Card>
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <Form
          addCategoryHandler={addCategoryHandler}
        />
      </Modal>

      <Modal
        outerClassName={styles.outer}
        visible={updateCategoryModal}
        onClose={() => setUpdateCategoryModal(false)}
      >
        <UpdateForm
          selectedCategory={selectedCategory}
          updateCategoryHandler={updateCategoryHandler}
        />
      </Modal>
    </>
  );
};

export default RecentPost;

import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Overview.module.sass";
import TooltipGlodal from "../../../components/TooltipGlodal";
import Card from "../../../components/Card";
import Icon from "../../../components/Icon";
import CustomDropdown from "../../../components/CustomDropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Overview = ({
  className,
  intervalText,
  setIntervalText,
  intervalValue,
  setIntervalvalue,
  intervalvalues,
  addressValue,
  setAddressvalue,
  addressText,
  setAddressText,
  addressTypeValues,
  financeData,
  financeDataHandler,
  endDate,
  startDate,
  setStartDate,
  setEndDate,
}) => {
  let items = [
    {
      title: "Total Transactions",
      counter: financeData.total_transactions,
      icon: "activity",
      color: "#B5E4CA",
    },
    {
      title: "Total Amount",
      counter: financeData.total_amount,
      icon: "payment",
      color: "#CABDFF",
    },
    {
      title: "Total Profit",
      counter: financeData.total_profit,
      icon: "pie-chart",
      color: "#FFBC99",
    },
    {
      title: "Completed Transactions",
      counter: financeData.completed,
      icon: "upload",
      color: "#B5E4CA",
    },
  ];

  if (addressText !== "Credited" && addressText !== "Debited") {
    items.push({
      title: "Decline Transactions",
      counter: financeData.decline,
      icon: "trash",
      color: "rgb(237 149 149)",
    });
  }

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Finance Insights"
        classTitle="title-blue"
        classCardHead={styles.head}
        head={
          <>
            <div className={styles.col}>
              <CustomDropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={addressValue}
                setValue={setAddressvalue}
                text={addressText}
                setText={setAddressText}
                options={addressTypeValues}
                small
              />
            </div>
            <div className={styles.col}>
              <CustomDropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={intervalValue}
                setValue={setIntervalvalue}
                text={intervalText}
                setText={setIntervalText}
                options={intervalvalues}
                small
              />
            </div>
            <div className={styles.row}>
              {intervalText === "Custom Range" && (
                <>
                  <div className={styles.col}>
                    <DatePicker
                      className={styles.customDate}
                      selected={startDate}
                      onChange={setStartDate}
                      startDate={startDate}
                      endDate={endDate}
                      showYearDropdown
                      maxDate={new Date()}
                    />
                  </div>
                  <div className={styles.col}>
                    <DatePicker
                      className={styles.customDate}
                      selected={endDate}
                      onChange={setEndDate}
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      showYearDropdown
                      maxDate={new Date()}
                    />
                  </div>
                </>
              )}
            </div>
          </>
        }
      >
        <div className={styles.overview}>
          <div className={styles.list}>
            {items.map((x, index) => (
              <div className={styles.item} key={index}>
                <div
                  className={styles.icon}
                  style={{ backgroundColor: x.color }}
                >
                  <Icon name={x.icon} size="24" />
                </div>
                <div className={styles.details}>
                  <div className={styles.label}>{x.title}</div>
                  <div className={styles.counter}>{x.counter}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Card>

      <TooltipGlodal />
    </>
  );
};

export default Overview;
